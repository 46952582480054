import api from '../../libs/API'
import useAuthentication from './useAuth';


const useTalentRequests = () => {
    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // Get talent profile information
    const getTalentProfileRequest = async (uuid, alreadyCalled) => {
        try {
            // Get token
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const talentProfile = await api.get(`/users/${uuid}`, { headers });
            return talentProfile.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getTalentProfileRequest(uuid, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Update talent profile information
    const updateTalentProfile = async (data, alreadyCalled) => {
        try {
            // Get token
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            // Decode token and save in local storage
            const payload = JSON.parse(atob(token.split('.')[1]));
            const uuid = payload.sub;

            const profileUpdated = await api.patch(`/users/${uuid}`, data, { headers });
            delete profileUpdated.data.city
            delete profileUpdated.data.phoneNumber
            localStorage.setItem('userInformation', JSON.stringify({
                role: payload.role,
                user: profileUpdated.data
            }));

            return profileUpdated.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        // Refresh token
                        await refreshToken();
                        await updateTalentProfile(data, true)
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Get list of cities with talents
    const getTalentCitiesRequest = async (filters, alreadyCalled) => {
        try {
            // Get token
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const { category, day } = filters;

            let query = {};

            if (category) query.category = category;
            if (day) query.day = day;

            const talentCities = await api.get(`/users/cities`, { params: query, headers });
            return talentCities.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getTalentCitiesRequest(filters, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };


    return {
        getTalentProfileRequest,
        getTalentCitiesRequest,
        updateTalentProfile
    };
};

export default useTalentRequests;