import React, { Fragment, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Custom
import useJobsRequests from '../../hooks/backend/useJobs';
import useDate from '../../hooks/useDates';

import ModalComponent from '../ModalComponent';

function TalentJobsCart({ jobInfo, jobListAnalyticsTracker }) {

    // ========== Custom hooks ============
    const { getJobType } = useJobsRequests();
    const { convertIsoToDMYFormat } = useDate();

    // Modal
    const [modalInformation, setModalInformation] = useState({});
    const [userInformation, setUserInformation] = useState(JSON.parse(localStorage.getItem('userInformation')).user);

    // ========== References ============ //
    const modalRef = useRef();

    // Navigation router
    const navigate = useNavigate();

    let location = jobInfo.remote === 1 ? 'Remoto' : `${jobInfo['city.name']}, ${jobInfo['city.state.name']}`;

    const handleMoreInformation = () => {

        if (!userInformation.profileCompletedFlag) {
            setModalInformation({
                title: '¡Actualiza tu perfil!',
                content: 'Para ver más información o aplicar a un empleo debes completar la información de un perfil',
                button2Content: 'Actualizar perfil',
                button2Redirect: 'my-profile',
            });
            openModal();
        } else {
            jobListAnalyticsTracker({ action: 'talent_click_job', label: jobInfo.uuid });
            navigate(`/jobs/${jobInfo.uuid}`);
        }

    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonOne() { window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null }
    function handleRedirectToButtonTwo() { window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null }

    return (
        <Fragment>

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                redirectButton1={modalInformation.button1Redirect && handleRedirectToButtonOne}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />
            <div className="job-cart box-shadow mb4">
                <div className="container">
                    <div className="row job-header">
                        <div className="col-lg-8 col-md-8 col-sm-10 col-10">
                            <div className="row">
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    {
                                        jobInfo['company.profile_image_url']
                                            ?
                                            <img
                                                src={jobInfo['company.profile_image_url']}
                                                alt="company-profile-photo"
                                            />
                                            :
                                            <img
                                                src="https://staffing-app.s3.amazonaws.com/profile-images/default-profile-picture.png"
                                                alt="Profile Picture Default"
                                                style={{ objectFit: 'contain' }}
                                            />
                                    }
                                    <div className=" job-title">
                                        <h4>{jobInfo.title}</h4>
                                        <h6>{jobInfo['company.name']}</h6>
                                        <h6>{location}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-2 col-2 align-center">
                            <div
                                onClick={handleMoreInformation}
                                className="more-information"
                            >
                                <button type="submit" className="btn btn-theme">Más información</button>
                                <div>
                                    <i className="fa-solid fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row job-content">
                        {
                            jobInfo.description.length >= 200 ?
                                <p>
                                    {jobInfo.description.slice(0, 200)}
                                    <a className='text-primary' href={`/jobs/${jobInfo.uuid}`}> ...más</a>
                                </p>
                                :
                                <p>
                                    {jobInfo.description}
                                </p>
                        }

                        <ul>
                            <li key={jobInfo['job_category.category.uuid']}>
                                {jobInfo['job_category.category.name']}
                            </li>
                        </ul>
                    </div>
                    <div className="row py10 job-footer">
                        <div className="col-4 align-center justify-content-center">
                            <i className="fa-solid fa-briefcase" />
                            <p>{getJobType[jobInfo.type]}</p>
                        </div>
                        <p className="col-4 align-center justify-content-center">{jobInfo.applicantsNumber} aplicantes</p>
                        <div className="col-4 align-center justify-content-center">
                            <i className="fa-regular fa-calendar" />
                            <p >{convertIsoToDMYFormat(jobInfo.createdAt)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TalentJobsCart