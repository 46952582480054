import React, { Fragment, useState, useEffect, useRef, lazy, Suspense } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// ----- Custom ----- //
import usePreferences from '../../hooks/backend/usePreferences';
import useOptions from '../../hooks/backend/useSelectorOptions';
import useHistoryRequests from '../../hooks/backend/useHistory';
import useJobsRequests from '../../hooks/backend/useJobs';
import useDate from '../../hooks/useDates';

// ----- Components ----- //
import ProfileHeader from '../../components/profile/ProfileHeaderComponent';
import TalentExperienceProfile from '../../components/profile/TalentExperienceProfile';
import CreatePreferencesModal from '../../containers/PreferencesModal';
const UpdatePreferencesModal = lazy(() => import('../../containers/UpdatePreferencesModal'))
const UpdateProfile = lazy(() => import('../../containers/UpdateProfile'));
const JobExperienceModal = lazy(() => import('../../containers/JobExperienceModal'));

function TalentProfile() {

    // ========== States ============ //
    const [profileInformation, setProfileInformation] = useState(
        JSON.parse(localStorage.getItem('userInformation')).user
    );
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [location, setLocation] = useState('');
    const [locationIds, setLocationIds] = useState({});
    const [days, setDays] = useState({
        Sunday: 'Domingo',
        Monday: 'Lunes',
        Tuesday: 'Martes',
        Wednesday: 'Miércoles',
        Thursday: 'Jueves',
        Friday: 'Viernes',
        Saturday: 'Sábado',
    });

    // --- Preferences list --- //
    const [categoryList, setCategoryList] = useState([]);
    const [categoryPreferencesList, setCategoryPreferencesList] = useState([]);
    const [timePreferencesList, setTimePreferencesList] = useState([]);
    const [jobHistoryList, setJobHistoryList] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [initialCategoryPreferences, setInitialCategoryPreferences] = useState([]);
    const [initialTimePreferences, setInitialTimePreferences] = useState([]);


    // ========== Custom ============ //
    const { getUserCategoryPreferencesRequest, getUserTimePreferencesRequest } = usePreferences();
    const { getLocationInformation, getCategoryOptions } = useOptions();
    const { getUserJobsHistoryRequest } = useHistoryRequests();
    const { getJobType } = useJobsRequests();
    const { convertYMDToSimplified } = useDate();

    // ========== References ============
    const updateProfileModalRef = useRef();
    const createPreferencesModalRef = useRef();
    const updatePreferencesModalRef = useRef();
    const createJobExperienceModalRef = useRef();

    // ========== Backend request ========== //
    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        const userInformation = JSON.parse(localStorage.getItem('userInformation'));
        const uuid = userInformation.user.uuid;

        // Complete location
        async function getCompleteLocationFunction(cityId) {
            await getLocationInformation(cityId)
                .then(response => {
                    setLocationIds({
                        cityId: response.id,
                        stateId: response.state.id,
                        countryId: response.state.country.id
                    });
                    const location = `${response.name}, ${response.state.name}, ${response.state.country.name}`;
                    setLocation(location);
                    return response;
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        // Categories
        async function getAllCategoriesFunction() {
            try {
                const categoriesResponse = await getCategoryOptions();
                setCategoryList(categoriesResponse);
                return categoriesResponse;
            } catch (error) {
                console.error(error.message);
            }
        }

        // User category preferences 
        async function getUserCategoryPreferences() {
            try {
                const categoryPreferencesResponse = await getUserCategoryPreferencesRequest(uuid);
                setCategoryPreferencesList(categoryPreferencesResponse);
                setInitialCategoryPreferences(categoryPreferencesResponse);
                return categoryPreferencesResponse;
            } catch (error) {
                console.error(error.message);
            }
        }

        // User time preferences
        async function getUserTimePreferences() {
            try {
                const timePreferencesResponse = await getUserTimePreferencesRequest(uuid);
                setTimePreferencesList(timePreferencesResponse);
                setInitialTimePreferences(timePreferencesResponse);
                return timePreferencesResponse;
            } catch (error) {
                console.error(error.message);
            }
        }

        // User jobs history
        async function getUserJobsHistoryFunction() {
            try {
                const userJobsHistoryResponse = await getUserJobsHistoryRequest(uuid);
                setJobHistoryList(userJobsHistoryResponse);
                return userJobsHistoryResponse;
            } catch (error) {
                console.error(error.message);
            }
        }

        async function fetchData() {
            try {
                await Promise.all([
                    profileInformation.cityId && location === '' && getCompleteLocationFunction(profileInformation.cityId),
                    getAllCategoriesFunction(),
                    getUserCategoryPreferences(),
                    getUserTimePreferences(),
                    jobHistoryList.length === 0 && getUserJobsHistoryFunction(),
                ])
                    .then((response) => {
                        // Response[1] = all categories
                        // Response[2] = user category preferences
                        setFilteredCategories(response[1].filter((element) => !response[2].some((item) => item.category.uuid === element.uuid)));
                    })
            } catch (error) {
                console.error(error.message);
            }
        }

        fetchData();

    }, [isInitialMount, profileInformation]);


    // Modal functions
    const openUpdateProfileModal = () => updateProfileModalRef.current.handleShow();
    const closeUpdateProfileModal = () => updateProfileModalRef.current.handleClose();
    const openCreatePreferencesModal = () => createPreferencesModalRef.current.handleShow();
    const closeCreatePreferencesModal = () => createPreferencesModalRef.current.handleClose();
    const openUpdatePreferencesModal = () => updatePreferencesModalRef.current.handleShow();
    const closeUpdatePreferencesModal = () => updatePreferencesModalRef.current.handleClose();
    const openCreateJobExperienceModal = () => createJobExperienceModalRef.current.handleShow();
    const closeCreateJobExperienceModal = () => createJobExperienceModalRef.current.handleClose();

    return (
        <Fragment>

            {/* Create preferences */}
            <CreatePreferencesModal
                ref={createPreferencesModalRef}
                handleClose={closeCreatePreferencesModal}
                categoryList={categoryList}
                setCategoryList={setCategoryList}
                categoryPreferencesList={categoryPreferencesList}
                setCategoryPreferencesList={setCategoryPreferencesList}
            />


            <Suspense
                fallback={
                    <div>
                        Loading...
                    </div>
                }
            >
                {/* Update preferences */}
                <UpdatePreferencesModal
                    ref={updatePreferencesModalRef}
                    handleClose={closeUpdatePreferencesModal}
                    categoryList={filteredCategories}
                    setCategoryList={setFilteredCategories}
                    initialCategoryPreferences={initialCategoryPreferences}
                    initialTimePreferences={initialTimePreferences}
                    categoryPreferencesList={categoryPreferencesList}
                    setCategoryPreferencesList={setCategoryPreferencesList}
                    timePreferencesList={timePreferencesList}
                    setTimePreferencesList={setTimePreferencesList}
                />
                {/* Update profile */}
                <UpdateProfile
                    ref={updateProfileModalRef}
                    handleClose={closeUpdateProfileModal}
                    userInformation={profileInformation}
                    user={'talent'}
                    location={locationIds}
                />

                {/* Create job experience */}
                <JobExperienceModal
                    ref={createJobExperienceModalRef}
                    handleClose={closeCreateJobExperienceModal}
                />

            </Suspense>


            <section id="profile-area" className="py40">
                <div className="container">
                    {/* <!-- Profile header --> */}

                    <ProfileHeader
                        type={'talent'}
                        openUpdateProfileModal={openUpdateProfileModal}
                        profileImageUrl={profileInformation.profileImageUrl}
                        name={`${profileInformation.firstName} ${profileInformation.middleName ? profileInformation.middleName : ""} ${profileInformation.lastName}`}
                        location={location}
                        description={profileInformation.description}
                        footer={{ lastCompany: jobHistoryList[0]?.companyName, lastEducation: 'Universidad de Caldas' }}
                    />

                    {/* <!-- Profile information --> */}
                    <div className="row profile-additional-info">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 center-section">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="profile-cart box-shadow">
                                        <div className="container">
                                            <Tabs
                                                defaultActiveKey="biography"
                                                id="fill-tab-example"
                                                className="mb-3 mt-1"
                                                fill
                                            >
                                                <Tab eventKey="biography" title="Biografía">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div className="profile-about-me">
                                                                    <h4>Sobre mi</h4>
                                                                    <p>
                                                                        {profileInformation.description}
                                                                    </p>
                                                                </div>
                                                                <div className="talent-profile-my-preferences">
                                                                    <div className='row talent-profile-preferences-header'>
                                                                        <h4 className="col-lg-6 col-md-6 col-sm-6 col-6 align-center">
                                                                            Preferencias
                                                                        </h4>
                                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                                            {
                                                                                categoryPreferencesList.length === 0
                                                                                    ?
                                                                                    <a
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            openCreatePreferencesModal();
                                                                                        }}
                                                                                        className="btn btn-theme btn-new-job"
                                                                                    >
                                                                                        <i style={{ marginRight: '2px' }} className="fas fa-solid fa-plus" />
                                                                                        Crear
                                                                                    </a>
                                                                                    :
                                                                                    <a
                                                                                        onClick={(event) => {
                                                                                            event.preventDefault();
                                                                                            openUpdatePreferencesModal();
                                                                                        }}
                                                                                        className="btn btn-theme btn-new-job"
                                                                                    >
                                                                                        <i style={{ marginRight: '2px' }} className="fas fa-solid fa-plus" />
                                                                                        Editar
                                                                                    </a>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                        <h5>Mis categorías</h5>
                                                                    </div>
                                                                    <div className="category-preferences">
                                                                        <ul>
                                                                            {
                                                                                categoryPreferencesList && categoryPreferencesList.map(category => {
                                                                                    if (category.category.uuid === 'e207272d-d465-4327-a80c-b76440cf7e03') return;
                                                                                    return (
                                                                                        <li
                                                                                            key={category.category.uuid}
                                                                                        >
                                                                                            <p
                                                                                                id={category.category.uuid}
                                                                                            >
                                                                                                {category.category.name}
                                                                                            </p>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>

                                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12 border-top'>
                                                                        <h5>Mi disponibilidad</h5>
                                                                    </div>
                                                                    <div className="category-preferences">
                                                                        <ul>
                                                                            {
                                                                                timePreferencesList && timePreferencesList.map(day => {
                                                                                    return (
                                                                                        <div className='row border-top' key={day.id}>
                                                                                            <h6 className='col-lg-3 col-md-3 col-sm-3 col-3'>
                                                                                                <strong> {days[day.day]} </strong>
                                                                                            </h6>
                                                                                            {
                                                                                                day.allDayFlag
                                                                                                    ?
                                                                                                    <p className='col-lg-6 col-md-6 col-sm-6 col-6'>Todo el día</p>
                                                                                                    :
                                                                                                    <p className='col-lg-9 col-md-9 col-sm-9 col-9'>
                                                                                                        {
                                                                                                            `${day.startHour.substr(0, day.startHour.length - 3)} - ${day.finishHour.substr(0, day.finishHour.length - 3)}`
                                                                                                        }
                                                                                                    </p>

                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="professional-experience" title="Experiencia" className='mt-1'>
                                                    <div className="container">
                                                        <div className="row talent-profile-jobs-header">
                                                            <h4 className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                                Mi experiencia laboral
                                                            </h4>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                                <a
                                                                    onClick={(event) => {
                                                                        event.preventDefault();
                                                                        openCreateJobExperienceModal();
                                                                    }}
                                                                    className="btn btn-theme btn-new-job"
                                                                >
                                                                    <i style={{ marginRight: '2px' }} className="fas fa-solid fa-plus" />
                                                                    Nuevo
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {/* <!-- Professional experience --> */}
                                                        {
                                                            jobHistoryList
                                                                ?
                                                                jobHistoryList.map(history => {
                                                                    const startDateConverted = convertYMDToSimplified(history.startDate);
                                                                    const finishDateConverted = history.actualJobFlag ? 'Presente' : convertYMDToSimplified(history.finishDate);
                                                                    const date = `${startDateConverted} - ${finishDateConverted}`;
                                                                    const location = history.remote ? 'Remote' : `${history.city.name}, ${history.city.state.name}, ${history.city.state.country.name}`
                                                                    return (
                                                                        < TalentExperienceProfile
                                                                            key={history.uuid}
                                                                            title={history.position}
                                                                            companyProfileImageUrl={history.company ? history.company.profile_image_url : ''}
                                                                            companyName={history.company ? history.company.name : history.companyName}
                                                                            location={location}
                                                                            description={history.description}
                                                                            footer={{ type: getJobType[history.type], date }}
                                                                        />
                                                                    )
                                                                })
                                                                :
                                                                <h4>{`${profileInformation.firstName} ${profileInformation.middleName ? profileInformation.middleName : ""}`} aún no tiene experiencia registrada en nuestra plataforma</h4>
                                                        }
                                                    </div>
                                                </Tab>
                                                {/* <Tab eventKey="education" title="Educación" className='mt-1' disabled>
                                                    <div className="container">
                                                        <div className="row profile-cart-inside py50">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 profile-header">
                                                                <div className="row profile-header-info">
                                                                    <div className="col-lg-8 col-md-8 col-sm-8 col-8 align-center">
                                                                        <div className="profile-name">
                                                                            <h4>Systems engineer</h4>
                                                                            <div className="profile-location">
                                                                                <i className="fa-solid fa-building"></i>
                                                                                <h6>Universidad de Caldas</h6>
                                                                            </div>
                                                                            <div className="profile-location">
                                                                                <i className="fa-solid fa-location-dot"></i>
                                                                                <h6>Manizales, Caldas</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="col-lg-4 col-md-4 col-sm-4 col-4 align-center edit-info">
                                                                        <div>
                                                                            <a href="">
                                                                                <i className="fas fa-edit"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                                                                    Quam maxime
                                                                    laudantium ea corporis incidunt dicta excepturi,
                                                                    reprehenderit libero
                                                                    quae
                                                                    deserunt, a nam dignissimos animi nostrum officiis, at vitae
                                                                    numquam
                                                                    neque.
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="row py10 profile-footer">
                                                            <div className="col-4 align-center justify-content-center">
                                                                <i className="fa-solid fa-calendar-days"></i>
                                                                <p>Nov 2022 - Presente</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab> */}
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default TalentProfile