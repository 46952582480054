import React, { useState, useContext, useRef, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import AppContext from '../context/AppContext';
import useAuthentication from '../hooks/backend/useAuth';

import LateralMenu from './LateralMenu';
import ModalComponent from '../components/ModalComponent';

import '../styles/components/header.scss';
const Logo = 'https://staffing-app.s3.amazonaws.com/logos/talento+rapido+color.png'

function Header() {

    // Context
    const { isLogged, role, showOnlyLogo } = useContext(AppContext);

    // ========== Routing ============ //
    const navigate = useNavigate();

    // ========== References ============ //
    const modalRef = useRef();

    // ========== Custom hooks ============ //
    const { logoutRequest } = useAuthentication();

    // ========== States ============ //
    const [toggleLateralMenu, setToggleLateralMenu] = useState(false); // Lateral menu
    const [toggleDropDown, setToggleDropDown] = useState(false); // Dropdown
    const [userInformation, setUserInformation] = useState(JSON.parse(localStorage.getItem('userInformation')));
    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // ======== Functions ========= //
    const handleLogOut = async (event) => {
        event.preventDefault();
        await logoutRequest(userInformation.user.uuid)
            .then(window.location.href = '/login')
            .catch(() => {
                openModal();
            });
    };

    const handleSearchNewApplicants = (event) => {
        event.preventDefault();
        if (!userInformation.user.categoryUuid) {
            setModalInformation({
                title: 'Actualiza tu perfil',
                content: 'Sólo podrás buscar posibles candidatos si buscas directamente en un empleo publicado o actualizas tu perfil y añades el sector en el que está tu compañía',
                button1Content: 'Ir a mis empleos',
                button1Redirect: '/my-jobs',
                button2Content: 'Actualizar perfil',
                button2Redirect: '/my-profile',
            });
            openModal()
        } else {
            navigate(`/talent-availability/${userInformation.user.categoryUuid}`);
        }
    };

    // Change the list in the navbar if the user is logged
    const renderList = () => {
        if (!isLogged) {
            return (
                <ul>
                    <li>
                        <a href="#banner-one">Home</a>
                    </li>
                    <li>
                        <a href="#service">Servicios</a>
                    </li>
                    <li>
                        <a href="#how-it-work-home4">Nosotros</a>
                    </li>
                </ul>
            );
        } else {
            if (role === 'company') {
                return (
                    <ul>
                        <li>
                            <a href="/my-jobs">Mis empleos</a>
                        </li>
                        <li>
                            <a href="#!" onClick={event => handleSearchNewApplicants(event)}>Buscar aplicantes</a>
                        </li>
                    </ul>
                );
            } else {
                return (
                    <ul>
                        <li>
                            <a href="/jobs">Empleos</a>
                        </li>
                        <li>
                            <a href="/my-applications">Mis aplicaciones</a>
                        </li>
                    </ul>
                );
            }
        }
    };

    // Change the right part of the navbar if the user is logged
    const renderRightNavbar = () => {
        if (isLogged) {
            return (
                <div className="navbar-right" onClick={() => setToggleDropDown(!toggleDropDown)}>
                    <ul>
                        <li className="navbar-username">
                            {
                                userInformation.role === 'company' ?
                                    userInformation.user.name
                                    :
                                    `${userInformation.user.firstName} ${userInformation.user.middleName ? userInformation.user.middleName : ''}`
                            }
                        </li>
                        <li className="navbar-img">
                            {
                                userInformation.user.profileImageUrl
                                    ?
                                    <img src={userInformation.user.profileImageUrl} alt={userInformation.user.firstName} />
                                    :
                                    <img
                                        src="https://staffing-app.s3.amazonaws.com/profile-images/default-profile-picture.png"
                                        alt={userInformation.user.firstName}
                                        style={{ objectFit: 'contain' }}
                                    />
                            }

                        </li>
                        {
                            toggleDropDown ? <i className="fa-solid fa-chevron-up" />
                                : <i className="fa-solid fa-chevron-down" />
                        }
                    </ul>
                    {
                        toggleDropDown &&
                        <div className="dropdown-content">
                            <a href="/my-profile"> <p> Ver perfil</p></a>
                            {/* <a href="/settings"> <p>Ajustes</p> </a> */}
                            <a
                                href="#!"
                                className='dropdown-sign-out'
                                onClick={event => handleLogOut(event)}
                            >
                                <p>Salir</p>
                            </a>
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div className="navbar-buttons">
                    <a className="btn btn-register" href="/register">Registrarme</a>
                    <a className="btn btn-login" href="/login">Ingresar</a>
                </div>
            )
        }
    };

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    function handleRedirectToButtonOne() {
        window.location.href = modalInformation.button1Redirect && modalInformation.button1Redirect
    }

    function handleRedirectToButtonTwo() {
        window.location.href = modalInformation.button2Redirect && modalInformation.button2Redirect
    }

    return (
        // Navbar start
        <Fragment>

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                redirectButton1={modalInformation.button1Redirect && handleRedirectToButtonOne}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <nav className="navbar-container">
                {
                    toggleLateralMenu && <LateralMenu closeLateralNavbar={setToggleLateralMenu} />
                }
                {
                    showOnlyLogo ?
                        <div className="navbar-logo justify-center">
                            <a href="/">
                                <img src={Logo} alt="talento-rapido" className="logo" />
                            </a>
                        </div>
                        :

                        <div className="container navbar-content">

                            {isLogged &&
                                <i
                                    className="fa-solid fa-bars fa-2xl menu"
                                    onClick={() => setToggleLateralMenu(!toggleLateralMenu)}
                                />
                            }

                            <div className="navbar-logo">
                                <a href="/">
                                    <img src={Logo} alt="talento-rapido" className="logo" />
                                </a>
                            </div>

                            <div className="navbar-list">
                                {renderList()}
                            </div>
                            {renderRightNavbar()}
                        </div>

                }
            </nav>
        </Fragment>
        // Navbar end
    )
}

export default Header