import React, { useState, useRef } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import '../../styles/components/auth.scss';

const validateInput = (type, value) => {
  switch (type) {
    case 'text':
      return (!/[^A-Za-zÀ-ÿ\s]+/.test(value));
    case 'email':
      return (!/[\])}>[<{(]/.test(value));
    default:
      break;
  }
};

function Login({ loginUser }) {

  // States
  const form = useRef(null);

  // Input content
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = React.useState(false);

  // Errors handlers
  const [errorEmail, setErrorEmail] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [errorPassword, setErrorPassword] = useState({ status: false, content: 'Este campo no debe estar vacío' });

  const [error, setError] = useState({ status: false, content: '' });



  // -------------- Functions ----------------

  // Validation
  const formValidation = (data) => {

    let flag = true;

    // Email
    if (!data.email && data.email === '') {
      setErrorEmail({ ...errorEmail, status: true });
      flag = false;
    };

    // Password
    if (data.password === '') {
      setErrorPassword({ ...errorPassword, status: true });
      flag = false;
    };

    return flag;
  };

  const loginWithGoogle = async () => {
    await loginUser('google');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const data = {
      email: formData.get('email'),
      password: formData.get('password'),
    };

    const validateForm = formValidation(data);

    if (validateForm) {
      // Make the petition to the backend
      await loginUser('email', {
        email: data.email,
        password: data.password
      }, 'company').catch(error => {
        setError({
          status: true,
          content: error
        });
      });
    }
  };

  const handleChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'email':
        setErrorEmail({ ...errorEmail, status: false });
        validateInput('email', inputValue) && setEmail(inputValue);
        break;
      case 'password':
        setErrorPassword({ ...errorPassword, status: false });
        validateInput('email', inputValue) && setPassword(inputValue);
        break;
      default:
        break;
    }
  };

  return (
    <section id="login-area" className="py100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="login-area">
              <div className="login-form-area">
                <div className="form-area-heading text-center">
                  <h3>¡Entra a tu cuenta!</h3>
                  <p>Con tu red social.</p>
                </div>
                <div className="social-login-area">
                  <button
                    type="submit"
                    onClick={loginWithGoogle}
                  >
                    <i className="fa-brands fa-google" />
                    Google
                  </button>
                </div>
                <form action="#" ref={form}>
                  <div className="row">
                    {/* Email */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          id="email"
                          size="small"
                          name="email"
                          label="Correo"
                          autoComplete='email'
                          variant="outlined"
                          onChange={handleChange}
                          value={email}
                          helperText={errorEmail.status && errorEmail.content}
                          error={errorEmail.status}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          id="outlined-adornment-password"
                          size="small"
                          name="password"
                          label="Contraseña"
                          autoComplete="current-password"
                          onChange={handleChange}
                          value={password}
                          helperText={errorPassword.status && errorPassword.content}
                          error={errorPassword.status}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endadornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={(event) => event.preventDefault()}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="password-remember text-right">
                        <a className="forget" href="/recover-password">¿Olvidaste tu contraseña?</a>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="login-button">
                        <button
                          type="submit"
                          className="btn btn-theme"
                          onClick={handleSubmit}
                        >
                          Entrar
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="login-desc text-center">
                        <p>¿No tienes una cuenta aún?<a href="/register"> Regístrate</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Login