import React, { Fragment, useState, useEffect, useRef, lazy, Suspense } from 'react';

// ----- Custom ----- //
import useOptions from '../../hooks/backend/useSelectorOptions';
import useJobsRequests from '../../hooks/backend/useJobs';
import useCompany from '../../hooks/backend/useCompany';

// ----- Components ----- //
import ModalComponent from '../../components/ModalComponent';
import ProfileHeader from '../../components/profile/ProfileHeaderComponent';
import CompanyJobsProfile from '../../components/profile/CompanyJobsProfile';
import JobModal from '../../components/jobs/JobModal';
const UpdateProfile = lazy(() => import('../../containers/UpdateProfile'));

function CompanyProfile() {

  // ========== States ============ //
  const [profileInformation, setProfileInformation] = useState(
    JSON.parse(localStorage.getItem('userInformation')).user
  );
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [location, setLocation] = useState('');
  const [jobToUpdate, setJobToUpdate] = useState({});
  const [jobsList, setJobsList] = useState([]);
  const [locationIds, setLocationIds] = useState({});
  // Modal
  const [modalInformation, setModalInformation] = useState({
    title: 'Error interno',
    content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
    button2Content: 'Volver',
    button2Redirect: '',
  });

  // ========== Custom ============
  const { getLocationInformation } = useOptions();
  const { getJobs } = useJobsRequests();
  const { getCompanyProfileRequest } = useCompany();

  // ========== References ============ //
  const modalRef = useRef();
  const newJobModalRef = useRef();
  const updateJobModalRef = useRef();
  const updateProfileModalRef = useRef();

  // ========== Backend request ========== //
  useEffect(() => {
    if (isInitialMount) {
      setIsInitialMount(false);
      return;
    }

    async function getCompleteLocationFunction(cityId) {
      await getLocationInformation(cityId)
        .then(response => {
          setLocationIds({
            cityId: response.id,
            stateId: response.state.id,
            countryId: response.state.country.id
          });
          const location = `${response.name}, ${response.state.name}, ${response.state.country.name}`;
          setLocation(location);
        })
        .catch((error) => {
          console.error(error);
          console.error(error.message);
          if (error.message === 'Por favor recarga la página') {
            window.location.reload();
          }
        });
    }

    async function jobListFunction() {

      const uuid = profileInformation.uuid;

      // Build filters object
      let filters = {
        limit: 4,
        oldest: 1,
        company: uuid
      }

      await getJobs(filters)
        .then(response => {
          setJobsList(response.jobs);
        })
        .catch(console.error)
    };

    // Search if the company is verified
    async function getCompanyInformation(companyUuid) {
      try {
        const companyResponse = await getCompanyProfileRequest(companyUuid);
        if (companyResponse.verifiedCompany) {
          const newProfileInformation = {
            ...profileInformation, verifiedCompany: true
          };

          delete newProfileInformation.firebaseId;
          localStorage.setItem('userInformation', JSON.stringify({
            role: 'company',
            user: newProfileInformation
          }));
          setProfileInformation(newProfileInformation);
        }
      } catch (error) {
        console.error(error.message);
      }
    }

    async function fetchData() {
      try {
        await Promise.all([
          jobListFunction(),
          profileInformation.cityId && location === '' ? getCompleteLocationFunction(profileInformation.cityId) : Promise.resolve([]),
          !profileInformation.verifiedCompany ? getCompanyInformation(profileInformation.uuid) : Promise.resolve([])
        ]);
      } catch (error) {
        console.error(error.message);
      }
    }
    fetchData();

  }, [isInitialMount, profileInformation]);

  // ======== Functions ========= //
  const handleUpdateJob = (event, job) => {
    event.preventDefault();
    setJobToUpdate(job);
    openUpdateJobModal()
  }

  const handleCreateJob = (event) => {
    event.preventDefault();
    if (profileInformation.verifiedCompany) {
      openNewJobModal();
    } else {
      setModalInformation({
        title: 'Esperando activación',
        content: 'Antes de crear un empleo debes esperar que se verifique tu empresa. Este proceso suele tardar menos de 24 horas',
        button2Content: 'Volver',
        button2Redirect: '',
      });
      openModal();
    }
  }

  // Modal functions
  const openModal = () => modalRef.current.handleShow();
  const closeModal = () => modalRef.current.handleClose();

  const openNewJobModal = () => newJobModalRef.current.handleShow();
  const closeNewJobModal = () => newJobModalRef.current.handleClose();
  const openUpdateJobModal = () => updateJobModalRef.current.handleShow();
  const closeUpdateJobModal = () => updateJobModalRef.current.handleClose();
  const openUpdateProfileModal = () => updateProfileModalRef.current.handleShow();
  const closeUpdateProfileModal = () => updateProfileModalRef.current.handleClose();

  function handleRedirectToButtonTwo() {
    window.location.href = modalInformation.button2Redirect && modalInformation.button2Redirect;
  }

  return (
    <Fragment>

      <JobModal
        ref={newJobModalRef}
        handleClose={closeNewJobModal}
      />

      <JobModal
        ref={updateJobModalRef}
        handleClose={closeUpdateJobModal}
        jobInfo={jobToUpdate}
      />

      <ModalComponent
        ref={modalRef}
        title={modalInformation.title}
        body={modalInformation.content}
        handleClose={closeModal}
        textButton2={modalInformation.button2Content}
        redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
      />

      <Suspense
        fallback={
          <div>
            Loading...
          </div>
        }
      >

        {/* Update profile */}
        <UpdateProfile
          ref={updateProfileModalRef}
          handleClose={closeUpdateProfileModal}
          userInformation={profileInformation}
          user={'company'}
          location={locationIds}
        />

      </Suspense>

      <section id="profile-area" className="py40">
        <div className="container">
          {/* <!-- Profile header --> */}
          <ProfileHeader
            type={'company'}
            openUpdateProfileModal={openUpdateProfileModal}
            profileImageUrl={profileInformation.profileImageUrl}
            name={profileInformation.name}
            location={location}
            description={profileInformation.description}
            footer={{ website: profileInformation.website }}
            verified={profileInformation.verifiedCompany}
          />

          {/* <!-- Jobs --> */}
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 company-jobs">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-center">
                <div className="profile-cart box-shadow">
                  <div className="container">
                    <div className="row company-profile-jobs-cart-inside">
                      <div className="row company-profile-jobs-header">
                        <h4 className="col-lg-6 col-md-6 col-sm-6 col-6">
                          Mis empleos
                        </h4>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <a
                            onClick={(event) => handleCreateJob(event)}
                            className="btn btn-theme btn-new-job"
                          >
                            <i style={{ marginRight: '2px' }} className="fas fa-solid fa-plus" />
                            Nuevo
                          </a>
                        </div>
                      </div>

                      <>
                        {
                          jobsList &&
                          jobsList.map(job => {
                            return (
                              <CompanyJobsProfile
                                job={job}
                                handleUpdateJob={handleUpdateJob}
                                key={job.uuid}
                              />
                            )
                          })
                        }
                      </>

                      <div className="row py10 profile-footer justify-content-end">
                        <div className="col-12 align-center justify-content-end">
                          <a href="/my-jobs">
                            <p> <strong> Ver más...</strong></p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment >
  )
}

export default CompanyProfile