import React, { Fragment, useEffect, useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';

// Custom
import useJobsRequests from '../../hooks/backend/useJobs';

const INITIAL_CATEGORY = 'e207272d-d465-4327-a80c-b76440cf7e03';

function JobListFilters(props) {

    const {
        // States values
        searchValue,
        setSearchValue,
        sortByOldest,
        setSortByOldest,
        categoryValue,
        setCategoryValue,
        companyValue,
        setCompanyValue,
        setCompanySizeValue,
        setCityValue,
        jobListAnalyticsTracker
    } = props;

    const categoryRef = useRef(null);

    // ========== Custom hooks ============
    const { getCategoryFilters, getCompaniesFilters, getCompanySizeFilters, getCitiesFilters } = useJobsRequests();

    // ========== States ============
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [categoryList, setCategoryList] = useState([{
        "category.uuid": INITIAL_CATEGORY,
        "category.name": 'Todas',
    }]);
    const [companySizeList, setCompanySizeList] = useState([{
        'company.size.id': 0,
        'company.size.size': 'Todas'
    }]);
    const [companiesList, setCompaniesList] = useState([{
        'company.name': "Todas",
        'company_uuid': 0
    }]);
    const [citiesList, setCitiesList] = useState([{
        'city.id': 0,
        'city.name': "Todas",
        'company_uuid': "0"
    }]);

    // ========== Backend request ==========  //
    // ----- Job Filters ----- //
    useEffect(() => {

        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function jobFiltersFunction() {
            const promises = [
                getCategoryFilters(),
                getCompaniesFilters(),
                getCompanySizeFilters(),
                getCitiesFilters()
            ];

            Promise.all(promises)
                .then(responses => {
                    setCategoryList(categoryList.concat(responses[0]));
                    setCompaniesList(companiesList.concat(responses[1]));
                    setCompanySizeList(companySizeList.concat(responses[2]));
                    setCitiesList(citiesList.concat(responses[3]));
                })
                .catch(console.error);
        };

        jobFiltersFunction();

    }, [isInitialMount]);

    return (
        <Fragment>
            {/* Search by job title start */}
            <div className="left-blog-page box-shadow">
                <div className="left-blog">
                    <h4>Título del empleo</h4>
                    <form action="#" className="mt4">
                        <div className="blog-search-option">
                            <input
                                type="text"
                                placeholder="Título..."
                                value={searchValue}
                                onChange={(event) => setSearchValue(event.target.value)}
                            />
                            <button className="button" type="submit"> <i className="fa fa-search"></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            {/* Search by job title end */}

            {/* Search by date */}
            <div className="left-blog-page box-shadow">
                <div className="left-blog">
                    <h4>Filtrar por</h4>
                    <select
                        className="form-select mt4"
                        aria-label="Filter by date"
                        onChange={(event) => setSortByOldest(event.target.value)}
                        defaultChecked={sortByOldest}
                    >
                        <option value="1" >Más reciente</option>
                        <option value="0">Más antiguo</option>
                    </select>
                </div>
            </div>

            {/* Categories */}
            <div className="left-blog-page box-shadow">
                <Form
                    className="left-blog"
                    ref={categoryRef}
                >
                    <h4>Categorías</h4>
                    {
                        categoryList && categoryList.map(category => {
                            return (
                                <div
                                    className="form-check mt4"
                                    key={category["category.uuid"]}
                                >
                                    <Form.Check
                                        style={{ paddingLeft: '0.1em' }}
                                        type="radio"
                                        id={category["category.uuid"]}
                                        name={`category-${category["category.name"]}`}
                                        label={category["category.name"]}
                                        value={category["category.uuid"]}
                                        checked={categoryValue === category["category.uuid"]}
                                        onChange={event => {
                                            jobListAnalyticsTracker({ action: 'talent_filter_jobs_by_category', label: event.target.value });
                                            setCategoryValue(event.target.value)
                                        }}
                                    />
                                </div>
                            );
                        })
                    }
                </Form>
            </div>

            {/* Location */}
            <div className="left-blog-page box-shadow">
                <div className="left-tags blog-tags">
                    <div className="popular-tag left-side-tags left-blog">
                        <h4>Ciudades</h4>
                        <ul>
                            {
                                citiesList && citiesList.map((city) => {
                                    if (city['city.id'] === null) return;
                                    return (
                                        <li
                                            key={city['city.id']}
                                            onClick={event => {
                                                event.preventDefault();
                                                jobListAnalyticsTracker({ action: 'talent_filter_jobs_by_city', label: event.target.id });
                                                setCityValue(event.target.id);
                                            }}
                                        >
                                            <a id={city['city.id']} href="">{city['city.name']}</a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>

            {/* Company Size */}
            <div className="left-blog-page box-shadow">
                <div className="left-tags blog-tags">
                    <div className="popular-tag left-side-tags left-blog">
                        <h4>Tamaño de empresas</h4>
                        <ul>
                            {
                                companySizeList && companySizeList.map((item) => {
                                    return (
                                        <li
                                            key={item['company.size.id']}
                                            onClick={event => {
                                                event.preventDefault();
                                                jobListAnalyticsTracker({ action: 'talent_filter_jobs_by_company_size', label: event.target.id });
                                                setCompanySizeValue(event.target.id);
                                            }}
                                        >
                                            <a id={item['company.size.id']} href="#">{item['company.size.size']}</a>
                                        </li>
                                    );
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>

            {/* Companies */}
            {/* <div className="left-blog-page box-shadow">
                <Form
                    className="left-blog"
                    ref={categoryRef}
                >
                    <h4>Empresas</h4>
                    {
                        companiesList && companiesList.map(company => {
                            return (
                                <div
                                    className="form-check mt4"
                                    key={company["company_uuid"]}
                                >
                                    <Form.Check
                                        style={{ paddingLeft: '0.1em' }}
                                        type="radio"
                                        id={company["company_uuid"]}
                                        name={`company-${company["company.name"]}`}
                                        label={company["company.name"]}
                                        value={company["company_uuid"]}
                                        checked={companyValue == company["company_uuid"]}
                                        onChange={event => {
                                            jobListAnalyticsTracker({ action: 'talent_filter_jobs_by_company', label: companyValue });
                                            setCompanyValue(event.target.value)
                                        }}
                                    />
                                </div>
                            );
                        })
                    }
                </Form>
            </div> */}
        </Fragment>
    )
}

export default JobListFilters;