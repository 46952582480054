
const useDate = () => {

    const monthNames = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    // ISO => Normal
    // 2023-05-03T14:26:35.000Z => 3 de Mayo de 2023
    const convertIsoToNormalFormat = (isoDate) => {

        // Create a Date object
        const date = new Date(isoDate);

        // Get day, month and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Get month name
        const monthName = monthNames[monthIndex];

        // Build date in normal format
        const formattedDate = `${day} de ${monthName} de ${year}`;

        return formattedDate;
    };

    // ISO => DD/MM/YYYY
    // 2023-05-03T14:26:35.000Z => 03/05/2023
    const convertIsoToDMYFormat = (isoDate) => {

        // Create a Date object
        const date = new Date(isoDate);

        // Get day, month and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();

        // Build date in normal format
        const formattedDate = `${day}/${monthIndex + 1}/${year}`;

        return formattedDate;
    };

    // Complete => YYYY-MM-DD
    // Tue May 02 2023 00:00:00 GMT-0500 (hora estándar de Colombia) => 2023-05-02
    // YYYY-MM-DD is backend format
    const convertCompleteDateToDMY = (completeDate) => {

        // Get day, month and year
        let day = completeDate.getDate();
        let month = completeDate.getMonth() + 1;
        const year = completeDate.getFullYear();

        if (day < 10) {
            day = '0' + day.toString();
        }

        if (month < 10) {
            month = '0' + month.toString();
        }

        // Build date in YYYY-MM-DD format
        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate;
    };

    // YYYY-MM-DD => Simplified
    // 2023-12-25 => Dic 2023
    const convertYMDToSimplified = (completeDate) => {

        // Get day, month and year
        const date = completeDate.split('-');
        const month = monthNames[date[1] - 1];
        const year = date[0];

        // Build date in simplified format
        const formattedDate = `${month.slice(0, 3)} ${year}`;

        return formattedDate;
    };

    // Today => DD/MM/YYYY
    // Today => 25/12/2023
    const getTodayDateYMD = () => {

        // Get day, month and year
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
        const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();

        // Build date in simplified format
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate;
    };


    return { convertIsoToNormalFormat, convertCompleteDateToDMY, convertIsoToDMYFormat, convertYMDToSimplified, getTodayDateYMD };
};


export default useDate;