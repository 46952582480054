import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useDate from '../../hooks/useDates';
import useEmailsRequests from '../../hooks/backend/useEmails';

const getStatus = {
    'requested_by_user': { className: '', status: 'Aplicado' },
    'requested_by_company': { className: 'text-info', status: 'Requerido' },
    'in_review': { className: 'text-warning', status: "En revisión" },
    'rejected_by_user': { className: 'text-danger', status: "Rechazado" },
    'rejected_by_company': { className: 'text-danger', status: "Rechazado" },
    'in_contact': { className: 'text-primary', status: "En contacto" },
    'hired': { className: 'text-success', status: "Contratado" },
};

function ApplicationCart(props) {

    // ========== Custom ==========  //
    const { convertIsoToNormalFormat } = useDate();

    const {
        applicationId,
        applicantUuid,
        image,
        status,
        date,
        title,
        description,
        type,
        handleUpdateApplicationStatus
    } = props

    const navigate = useNavigate();

    // ========== States ============
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [request, setRequest] = useState({});

    // ========== Custom hooks ============
    const { sendProfileViewedEmailRequest } = useEmailsRequests();


    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function sendProfileViewedEmailFunction() {
            if (request.id) {

                await sendProfileViewedEmailRequest(request)
                    .then(() => {
                        navigate('/my-profile');
                    })
                    .catch(console.error())
            }
        }

        sendProfileViewedEmailFunction();
    }, [request]);

    // ========== Functions ==========  //
    const handleVisitProfile = (event) => {
        event.preventDefault();
        if (status === 'requested_by_user' || status === 'requested_by_company') {
            setRequest({ id: applicationId });
            navigate(`/talent/${applicantUuid}`);
        } else {
            navigate(`/talent/${applicantUuid}`);
        }
    };

    return (
        <Fragment>
            <div className="applicant-items box-shadow">
                <div className="applicant-imgs ">
                    <a href="">
                        {
                            image
                                ?
                                <img src={image} alt="cover" />
                                :
                                <img
                                    src="https://staffing-app.s3.amazonaws.com/profile-images/default-profile-picture.png"
                                    alt="Profile Picture Default"
                                    style={{ objectFit: 'contain' }}
                                />
                        }
                        <div className="applicant-status">
                            <p className={getStatus[status].className}> <b>{getStatus[status].status}</b></p>
                        </div>
                    </a>
                </div>
                <div className="applicant-info">
                    <div className="meta-area">
                        <ul>
                            <li><i style={{ marginRight: '2px' }} className="fas fa-light fa-calendar"></i>
                            </li>
                            <li>
                                <p>{convertIsoToNormalFormat(date)}</p>
                            </li>
                        </ul>
                    </div>
                    <h4>{title}</h4>
                    <p className='applicant-info-description'>
                        {
                            description ?
                                window.innerWidth > 1500 ?
                                    `${description.slice(0, 200)}...`
                                    :
                                    window.innerWidth < 900 ?
                                        `${description.slice(0, 100)}...`
                                        :
                                        description
                                :
                                ''
                        }
                    </p>
                    <div className='applicant-info-buttons'>
                        {
                            // Validate type of cart
                            type === 'jobApplicants'
                                ?
                                <a
                                    href=""
                                    onClick={(event) => handleVisitProfile(event)}
                                    className="btn btn-theme btn-card mt4"
                                >
                                    Ver aplicación
                                </a>
                                :
                                <a href={`application/${applicationId}`} className="btn btn-theme btn-card mt4">Ver aplicación</a>
                        }
                        {
                            // Validate type of cart
                            type === 'jobApplicants'
                                ?
                                // ----- Applicants of a job ----- //
                                <a
                                    href=""
                                    id={applicationId}
                                    className="btn btn-theme btn-card mt4"
                                    onClick={(event) => handleUpdateApplicationStatus({ event, status: getStatus[status].status, applicationId })}
                                >
                                    Actualizar
                                </a>
                                :
                                // ----- My jobs ----- //
                                <a href="/jobs" className="btn btn-theme btn-card mt4">Otros empleos</a>
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ApplicationCart