import React, { Fragment } from 'react';

function TalentExperienceProfile(props) {

    const {
        title,
        companyProfileImageUrl,
        companyName,
        location,
        description,
        footer
    } = props;

    const jobLocation = location;
    const colNumber = companyProfileImageUrl ? 9 : 12;

    return (
        <Fragment>
            <div className="row job-info">
                {
                    companyProfileImageUrl &&
                    <div
                        className="col-lg-3 col-md-3 col-sm-3 col-3 profile-image center-section pb24">
                        <img
                            src={companyProfileImageUrl}
                            alt="company"
                        />
                    </div>
                }
                <div className={`col-lg-${colNumber} col-md-${colNumber} col-sm-${colNumber} col-${colNumber} pb12`}>
                    <div className="row profile-header-info">
                        <div className="col-lg-10 col-md-10 col-sm-10 col-10 align-center">
                            <div className="profile-name">
                                <h4>{title}</h4>
                                {
                                    companyName &&
                                    <div className="profile-location">
                                        <i className="fa-solid fa-building"></i>
                                        <h6>{companyName}</h6>
                                    </div>
                                }
                                <div className="profile-location">
                                    <i className="fa-solid fa-location-dot"></i>
                                    <h6>{jobLocation}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 col-2 edit-job-experience">
                            <div>
                                {/* <button type="submit" className="btn btn-theme">
                                    Editar
                                </button> */}
                                <div>
                                    <a href="">
                                        <i className="fa-solid fa-ellipsis-vertical" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        description.length >= 200 ?
                            <p className="job-description">
                                {description.slice(0, 200)}
                                <a className='text-primary' href={`/jobs`}> ...more</a>
                            </p>
                            :
                            <p className="job-description">
                                {description}
                            </p>
                    }
                    <p className='job-description-2'>
                        {description.slice(0, 100)}
                        <a className='text-primary' href={`/jobs`}> ...more</a>
                    </p>
                </div>
                <div className="row py10 job-footer">
                    <div className="col-4 center-section">
                        <i className="fa-solid fa-suitcase" />
                        <p>{footer.type}</p>
                    </div>
                    <div className="col-4 center-section">
                        <i className="fa-solid fa-calendar-days"></i>
                        <p>{footer.date}</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default TalentExperienceProfile