import React, { useState, useRef, useEffect } from 'react'
import { Form } from 'react-bootstrap';

import {
  TextField,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel
} from '@mui/material';

// Custom hooks
import useS3Request from '../../hooks/useS3Request';
import useOptions from '../../hooks/backend/useSelectorOptions';
import useCompany from '../../hooks/backend/useCompany';
import useEmailsRequests from '../../hooks/backend/useEmails';
import useAnalytics from '../../hooks/useAnalytics';

// Components
import TooltipComponent from '../TooltipComponent';
import ModalComponent from '../ModalComponent';

import '../../styles/components/auth.scss';

const validateInput = (type, value) => {
  switch (type) {
    case 'text':
      return (!/[^A-Za-zÀ-ÿ\s]+/.test(value));
    case 'number':
      return (/^[0-9]*$/.test(value));
    case 'email':
      return (!/[\])}>[<{(]/.test(value));
    default:
      break;
  }
};

const errorObject = {
  status: false,
  content: 'Este campo no debe estar vacío'
};

function RegisterStep3Company(props) {

  // Custom hooks
  const { uploadFile } = useS3Request();
  const { updateCompanyProfile } = useCompany();
  const { sendNewCompanyEmailRequest } = useEmailsRequests();
  const { getCountries, getStates, getCities, getCompanySizeOptions, getCategoryOptions } = useOptions();
  const signUpAnalyticsTracker = useAnalytics('Sign up');

  // References
  const modalRef = useRef();
  const form = useRef(null);

  // States
  const [isInitialMount, setIsInitialMount] = useState(true);
  const [companyInformation, setCompanyInformation] = useState({
    uuid: props.companyInformation.uuid,
    email: props.companyInformation.email,
    companyName: props.companyInformation.companyName
  });
  // Modal
  const [modalInformation, setModalInformation] = useState({
    title: 'Error en la carga de archivo',
    content: 'El contenido cargado no es una imagen',
    button2Content: 'Volver'
  });

  // errors
  const [errorCompanyName, setErrorCompanyName] = useState(errorObject);
  const [errorEmail, setErrorEmail] = useState(errorObject);
  const [errorCountry, setErrorCountry] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
  const [errorRegion, setErrorRegion] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
  const [errorCity, setErrorCity] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
  const [errorCompleteAddress, setErrorCompleteAddress] = useState(errorObject);
  const [errorWorkersNumber, setErrorWorkersNumber] = useState({ status: false, content: 'Este campo debe estar seleccionado' });
  const [errorAboutUser, setErrorAboutUser] = useState(errorObject);
  const [TooltipProfileImage, setTooltipProfileImage] = useState({ status: false, content: 'Este campo debe estar seleccionado' });

  // Input content
  const [companyName, setCompanyName] = useState(companyInformation.companyName);
  const [email, setEmail] = useState(companyInformation.email);
  const [website, setWebsite] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [completeAddress, setCompleteAddress] = useState('');
  const [selectedWorkers, setSelectedWorkers] = useState('');
  const [otherOption, setOtherOption] = useState('');
  const [aboutUser, setAboutUser] = useState('');
  const [legallyRegistered, setLegallyRegistered] = useState('Si');
  const [objectToSend, setObjectToSend] = useState({});


  const [profileImageFile, setProfileImageFile] = useState('');

  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [companySizeList, setCompanySizeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  // ======= Request to backend ===========
  useEffect(() => {

    // Countries
    async function getCountryList() {
      try {
        const countryResponse = await getCountries();
        setCountryList(countryResponse);
      } catch (error) {
        console.error(error.message);
      }
    };

    // Company size
    async function getCompanySizeList() {
      try {
        const companySizeResponse = await getCompanySizeOptions();
        setCompanySizeList(companySizeResponse);
      } catch (error) {
        console.error(error.message);
      }
    };

    // States
    async function getStatesList() {
      if (selectedCountry !== "País") {
        try {
          const statesResponse = await getStates(selectedCountry);
          setStateList(statesResponse);
        } catch (error) {
          setStateList([]);
          console.error(error.message);
        }
      }
    }

    // Cities
    async function getCitiesList() {
      if (selectedRegion !== "Departamento") {
        try {
          const citiesResponse = await getCities(selectedRegion);
          setCityList(citiesResponse);
        } catch (error) {
          console.error(error.message);
        }
      }
    }

    // Cities
    async function getCategoriesList() {
      if (categoryList.length === 0) {
        try {
          const categoriesResponse = await getCategoryOptions(selectedRegion);
          setCategoryList(categoriesResponse);
        } catch (error) {
          console.error(error.message);
        }
      }
    }

    async function fetchData() {
      try {
        await Promise.all([
          getCountryList(),
          getCompanySizeList(),
          selectedCountry ? getStatesList() : Promise.resolve([]),
          selectedRegion ? getCitiesList() : Promise.resolve([]),
          categoryList.length === 0 ? getCategoriesList() : Promise.resolve([]),
        ]);
      } catch (error) {
        console.error(error.message);
      }
    }
    fetchData();
  }, [selectedCountry, selectedRegion]);

  useEffect(() => {

    if (isInitialMount) {
      setIsInitialMount(false);
      return;
    }

    async function updateProfileCompanyFunction() {
      await updateCompanyProfile(objectToSend)
        .then(() => signUpAnalyticsTracker({ action: 'company_step_3_completed', label: companyInformation.uuid }))
        .catch(error => console.error(error))
    }

    async function sendEmailFunction() {
      await sendNewCompanyEmailRequest({ uuid: companyInformation.uuid })
        .then(window.location.href = 'my-profile')
        .catch(console.error);
    }

    async function fetchData() {
      try {
        await Promise.all([
          objectToSend.cityId !== '' ? updateProfileCompanyFunction() : Promise.resolve([]),
          objectToSend.cityId !== '' ? sendEmailFunction() : Promise.resolve([])
        ]);
      } catch (error) {
        console.error(error.message);
      }
    }
    fetchData();

  }, [objectToSend])


  // ============= Functions ===============

  const handleCountryChange = (event) => {
    setErrorCountry({ ...errorCountry, status: false });
    setSelectedCountry(event.target.value);
  };

  const handleRegionChange = (event) => {
    setErrorRegion({ ...errorRegion, status: false });
    setSelectedRegion(event.target.value);
  };

  const handleCityChange = (event) => {
    setErrorCity({ ...errorCity, status: false });
    setSelectedCity(event.target.value);
  };

  const handleWorkersChange = (event) => {
    setErrorWorkersNumber({ ...errorWorkersNumber, status: false });
    setSelectedWorkers(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setTooltipProfileImage({ ...TooltipProfileImage, status: false });
    if (/\bimage\b/i.test(file.type)) {
      setProfileImageFile(file);
    } else {
      setModalInformation({
        ...modalInformation,
        content: `El archivo ${file.name} no es una imagen`
      });
      openModal();
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const data = {
      companyName: formData.get('companyName'),
      email: formData.get('email'),
      website: formData.get('website'),
      country: formData.get('country'),
      region: formData.get('region'),
      city: formData.get('city'),
      completeAddress: formData.get('completeAddress'),
      workersNumber: formData.get('workersNumber'),
      aboutUser: formData.get('aboutUser'),
      companyRegistered: formData.get('companyRegistered')
    };

    const validateForm = formValidation(data);

    if (validateForm) {
      const objectValidated = {
        name: data.companyName,
        email: data.email,
        website: data.website,
        cityId: data.city,
        sizeId: data.workersNumber,
        description: data.aboutUser,
        profileImageUrl: data.profileImageUrl,
        legallyRegistered: legallyRegistered === 'Si' ? true : false
      };

      if (selectedCategory !== '') objectValidated.categoryUuid = selectedCategory;

      // Make the petition to the backend
      await uploadFile(profileImageFile, 'profile-images')
        .then((response) => objectValidated.profileImageUrl = response)
        .catch(console.error);

      setObjectToSend(objectValidated);
    }
  };

  const formValidation = (data) => {

    let flag = true;

    // Company name
    if (!data.companyName && data.companyName === '') {
      setErrorCompanyName({ ...errorCompanyName, status: true });
      flag = false;
    };

    // Email
    if (!data.email && data.email === '') {
      setErrorEmail({ ...errorEmail, status: true });
      flag = false;
    };

    // Country
    if (data.country === 'País' || data.country === '') {
      setErrorCountry({ ...errorCountry, status: true });
      flag = false;
    };

    // Region
    if (data.region === 'Departamento' || data.region === '') {
      setErrorRegion({ ...errorRegion, status: true });
      flag = false;
    };

    // City
    if (data.city === 'Ciudad' || data.city === '') {
      setErrorCity({ ...errorCity, status: true });
      flag = false;
    };

    // Workers number
    if (data.workersNumber == 'Número de trabajadores') {
      setErrorWorkersNumber({ ...errorWorkersNumber, status: true });
      flag = false;
    };

    // Profile image
    if (!profileImageFile) {
      setTooltipProfileImage({ ...TooltipProfileImage, status: true });
      flag = false;
    };

    return flag;
  };

  const handleInputChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'companyName':
        setErrorCompanyName({ ...errorCompanyName, status: false });
        validateInput('text', inputValue) && setCompanyName(inputValue);
        break;
      case 'email':
        setErrorEmail({ ...errorEmail, status: false });
        validateInput('email', inputValue) && setEmail(inputValue);
        break;
      case 'website':
        validateInput('email', inputValue) && setWebsite(inputValue);
        break;
      case 'completeAddress':
        setErrorCompleteAddress({ ...errorCompleteAddress, status: false });
        validateInput('email', inputValue) && setCompleteAddress(inputValue);
        break;
      case 'otherOption':
        setOtherOption({ ...otherOption, status: false });
        validateInput('text', inputValue) && setOtherOption(inputValue);
        break;
      case 'aboutUser':
        setErrorAboutUser({ ...errorAboutUser, status: false });
        validateInput('email', inputValue) && setAboutUser(inputValue);
        break;
      default:
        break;
    }
  }

  // Modal functions
  const openModal = () => modalRef.current.handleShow();
  const closeModal = () => modalRef.current.handleClose();

  return (
    <section id="login-area" className="py100">
      <ModalComponent
        ref={modalRef}
        title={modalInformation.title}
        body={modalInformation.content}
        handleClose={closeModal}
        textButton2={modalInformation.button2Content}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="login-area">
              <div className="login-form-area">
                <div className="form-area-heading text-center">
                  <h3>Información adicional</h3>
                  <p>Un último paso y quedas listo.</p>
                </div>
                <form action="#" className="form-floating" ref={form}>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          id="companyName"
                          size="small"
                          name="companyName"
                          label="Nombre de la empresa"
                          autoComplete='given-name'
                          variant="outlined"
                          onChange={handleInputChange}
                          value={companyName}
                          helperText={errorCompanyName.status && errorCompanyName.content}
                          error={errorCompanyName.status}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          id="email"
                          size="small"
                          name="email"
                          label="Correo electrónico"
                          autoComplete='email'
                          variant="outlined"
                          onChange={handleInputChange}
                          value={email}
                          helperText={errorEmail.status && errorEmail.content}
                          error={errorEmail.status}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <TextField
                          fullWidth
                          id="website"
                          size="small"
                          name="website"
                          label="Sitio web"
                          autoComplete='url'
                          variant="outlined"
                          onChange={handleInputChange}
                          value={website}
                        />
                      </div>
                    </div>

                    {/* Country */}
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          select
                          id="country"
                          name='country'
                          autoComplete="country"
                          size="small"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          label='País'
                          helperText={errorCountry.status && errorCountry.content}
                          error={errorCountry.status}
                        >
                          <MenuItem value="">
                            <em>País</em>
                          </MenuItem>
                          {countryList.map((country) => (
                            <MenuItem key={country.id} value={country.id}> {country.name} </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    {/* Region */}
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          select
                          id="region"
                          name='region'
                          autoComplete="address-level1" // Region
                          size="small"
                          value={selectedRegion}
                          onChange={handleRegionChange}
                          label='Dpto'
                          helperText={errorRegion.status && errorRegion.content}
                          error={errorRegion.status}
                        >
                          <MenuItem value="">
                            <em>Departamento</em>
                          </MenuItem>
                          {stateList.map((region) => (
                            <MenuItem key={region.id} value={region.id}> {region.name} </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    {/* City */}
                    <div className="col-lg-4 col-md-4 col-sm-4">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          select
                          id="city"
                          name='city'
                          autoComplete="address-level2" // City
                          size="small"
                          value={selectedCity}
                          onChange={handleCityChange}
                          label='Ciudad'
                          helperText={errorCity.status && errorCity.content}
                          error={errorCity.status}
                        >
                          <MenuItem value="">
                            <em>Ciudad</em>
                          </MenuItem>
                          {cityList.map((city) => (
                            <MenuItem key={city.id} value={city.id}> {city.name} </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          id="completeAddress"
                          size="small"
                          name="completeAddress"
                          autoComplete="address-line1"
                          label="Dirección completa"
                          variant="outlined"
                          onChange={handleInputChange}
                          value={completeAddress}
                          helperText={errorCompleteAddress.status && errorCompleteAddress.content}
                          error={errorCompleteAddress.status}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          select
                          id="workersNumber"
                          name='workersNumber'
                          size="small"
                          value={selectedWorkers}
                          onChange={handleWorkersChange}
                          label='Número de trabajadores'
                          helperText={errorCity.status && errorCity.content}
                          error={errorCity.status}
                        >
                          <MenuItem value="">
                            <em>Número de trabajadores</em>
                          </MenuItem>
                          {companySizeList && companySizeList.map((workerNumber) => (
                            <MenuItem key={workerNumber.id} value={workerNumber.id}> {workerNumber.size} </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">

                        <TextField
                          fullWidth
                          select
                          id="category"
                          name='category'
                          size="small"
                          value={selectedCategory}
                          onChange={handleCategoryChange}
                          label='Sector'
                        >
                          <MenuItem value="">
                            <em>Otra</em>
                          </MenuItem>
                          {categoryList && categoryList.map((category) => (
                            category.uuid !== 'e207272d-d465-4327-a80c-b76440cf7e03'
                              ?
                              <MenuItem key={category.uuid} value={category.uuid}>
                                {category.name}
                              </MenuItem>
                              :
                              ''
                          ))}
                        </TextField>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">

                      <TextField
                        fullWidth
                        multiline
                        id="about-user"
                        size="small"
                        name="aboutUser"
                        label="Descripción"
                        placeholder='Acá puedes tu compañía: a qué se dedican y un poco sobre la cultura'
                        variant="outlined"
                        onChange={handleInputChange}
                        value={aboutUser}
                        rows={3}
                        maxRows={Infinity}
                      />
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt4">
                      <label htmlFor="profile-photo" className="form-label" style={{ 'marginBottom': '0.12rem' }}>
                        Foto de perfil*
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="profile-photo"
                        name='profile-photo'
                        onChange={handleFileChange}
                      />
                      <TooltipComponent
                        attributeName={'profile-photo'}
                        showTrigger={TooltipProfileImage.status}
                        errorText={TooltipProfileImage.content}
                      />
                    </div>

                    <div className="col-lg-8 col-md-8 col-sm-12 col-12 mt4">
                      <FormControl >
                        <FormLabel id="signUp-controlled-radio-buttons-group">¿Está la compañía legalmente registrada?</FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="signUp-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={legallyRegistered}
                          onChange={(event) => setLegallyRegistered(event.target.value)}
                        >
                          <FormControlLabel value="Si" control={<Radio />} label="Si" />
                          <FormControlLabel value="No" control={<Radio />} label="No" />
                        </RadioGroup>
                      </FormControl>
                      {/* <h6>¿Está la compañía legalmente registrada?</h6>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                          id="flexRadioDefault1" />
                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                          Si
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="flexRadioDefault"
                          id="flexRadioDefault2" defaultChecked />
                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                          No
                        </label>
                      </div> */}
                    </div>


                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="login-button">
                        <button
                          type="submit"
                          className="btn btn-theme"
                          onClick={handleSubmit}
                        >
                          Actualizar perfil
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegisterStep3Company