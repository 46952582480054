import React, { Fragment, useState, useEffect, useRef, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom';

// ----- Components ----- //
import TalentAvailabilityCart from '../../components/TalentAvailability/TalentAvailabilityCart';
import TalentAvailabilityFilters from '../../components/TalentAvailability/TalentAvailabilityFilters';
import TalentAvailabilityHeader from '../../components/TalentAvailability/TalentAvailabilityHeader';

import JobModal from '../../components/jobs/JobModal';
import PaginationComponent from '../../components/PaginationComponent';
import JobApplication from '../../components/jobs/JobApplication';
import ModalComponent from '../../components/ModalComponent';
import LateralFilters from '../../components/LateralFilters';
import FilterButtonComponent from '../../components/FilterButtonComponent';

// ----- Custom ----- //
import usePreferences from '../../hooks/backend/usePreferences';
import useJobsRequests from '../../hooks/backend/useJobs';

import '../../styles/pages/talent-availability.scss';

// ----- Constants ----- //
const INITIAL_PAGE = 1;
const TALENTS_PER_PAGE = 10;

function TalentAvailability() {

    // ========== Routing and params ============ //
    const { categoryUuid } = useParams();
    const navigate = useNavigate();

    // ========== Custom hooks ============ //
    const { getFilteredUsersList } = usePreferences();
    const { getJobs } = useJobsRequests();

    // ========== States ============ //
    const [toggleLateralFilters, setToggleLateralFilters] = useState(false);
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [jobInformation, setJobInformation] = useState(JSON.parse(sessionStorage.getItem('jobInformation')));
    const [talentToContact, setTalentToContact] = useState({});
    const [category, setCategory] = useState(categoryUuid);
    const [selectedJob, setSelectedJob] = useState(JSON.parse(sessionStorage.getItem('jobInformation')) && JSON.parse(sessionStorage.getItem('jobInformation')).uuid);

    const [talentList, setTalentList] = useState([]);
    const [jobsList, setJobsList] = useState([]);

    // ----- Filter states ----- //
    const [searchValue, setSearchValue] = useState('');
    const [cityValue, setCityValue] = useState(0);

    // Pagination
    const [page, setPage] = useState(INITIAL_PAGE);
    const [totalTalents, setTotalTalents] = useState(0);

    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor recarga la página',
        button2Content: 'Recargar',
        button2Redirect: 'reload',
    });

    // ========== References ============ //
    const modalRef = useRef();
    const applicationModalRef = useRef();
    const jobModalRef = useRef();

    // ----- Name filter logic ----- //
    let searchedTalents = []; // Variable to save matches
    if (!searchValue.length >= 1) {
        searchedTalents = talentList;
    } else {
        searchedTalents = talentList.filter(talent => {
            const fullName = `${talent.first_name} ${talent.middle_name ? talent.middle_name : ""} ${talent.last_name}`;
            const talentText = fullName.toLowerCase();
            const searchText = searchValue.toLowerCase();
            return talentText.includes(searchText);
        });
    };

    // ========== Backend request ==========  //
    // ----- Job List ----- //
    useEffect(() => {

        // Validate that the category is an uuid
        if (categoryUuid.length !== 36) {
            navigate('/my-jobs');
        }

        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        const companyUuid = JSON.parse(localStorage.getItem('userInformation')).user.uuid;

        // List of jobs 
        async function getJobsListFunction() {
            // Build filters object
            let filters = {
                limit: 10,
                page: 0,
                company: companyUuid
            };

            await getJobs(filters)
                .then(response => {
                    setJobsList(response.jobs);

                    // Validate jobs
                    if (response.jobs.length !== 0 && !jobInformation?.uuid) {
                        // Choose the default Job
                        const defaultJob =
                            response.jobs.find(element => element['job_category.category.uuid'] === categoryUuid)
                            || response.jobs[0];
                        setSelectedJob(defaultJob.uuid);

                        // Save in session storage
                        sessionStorage.setItem('jobInformation', JSON.stringify(defaultJob));
                    }

                    // Close lateral filters if its open
                    setToggleLateralFilters(false);
                })
                .catch(() => openModal());
        }

        // List of talents
        async function getUsersFunction() {

            // Build filters object
            let filters = {
                limit: TALENTS_PER_PAGE,
                page,
                category
            };

            if (selectedJob) filters.jobUuid = selectedJob;

            // City
            if (cityValue === '0') {
                delete filters.city;
            } else {
                filters['city'] = cityValue;
            }

            await getFilteredUsersList(filters)
                .then(response => {
                    setTalentList(response.talents);
                    setTotalTalents(response.totalTalents)
                })
                .catch(() => openModal());
        };

        async function fetchData() {
            try {
                await Promise.all([
                    getUsersFunction(),
                    jobsList.length === 0 ? getJobsListFunction() : Promise.resolve([]),

                ]);
            } catch (error) {
                console.error(error.message);
                openModal();
            }
        }
        fetchData();

    }, [page, category, isInitialMount, cityValue]);

    // ========== Functions ==========  //

    // Pagination handler
    const handlePageChange = (currentPage) => {
        setPage(currentPage);
    };

    // Job creation handler
    const handleCreateJob = (event) => {
        event.preventDefault();
        if (JSON.parse(localStorage.getItem('userInformation')).user.verifiedCompany) {
            openJobModal();
        } else {
            setModalInformation({
                title: 'Esperando activación',
                content: 'Antes de crear un empleo debes esperar que se verifique tu empresa. Este proceso suele tardar menos de 24 horas',
                button2Content: 'Volver',
                button2Redirect: '',
            });
            openModal();
        }
    }

    // "Contactar" button handler
    const handleRequestContact = (event, talentUuid) => {
        event.preventDefault();
        if (jobInformation && jobsList !== 0) {
            const talent = talentList.find(element => element.uuid === talentUuid);
            setTalentToContact(talent);
            openApplicationModal();
        } else {
            setModalInformation({
                title: '¡Crea un trabajo primero!',
                content: 'Para contactar a algún talento primero debes crear un empleo',
                button2Content: 'Crear',
                button2Redirect: 'new-job',
            });
            openModal();
        }
    };

    const handleVisitProfile = (event, uuid) => {
        event.preventDefault();
        if (jobInformation && jobsList !== 0) {
            const talentUuid = talentList.find(talent => talent.uuid === uuid).uuid;
            navigate(`/talent/${talentUuid}`);
        } else {
            setModalInformation({
                title: '¡Crea un trabajo primero!',
                content: 'Para contactar a algún talento primero debes crear un empleo',
                button2Content: 'Crear',
                button2Redirect: 'new-job',
            });
            openModal();
        }
    };

    // Handle click to filter with a different job
    const handleJobClick = (jobUuid, categoryUuid) => {
        setCategory(categoryUuid);
        setSelectedJob(jobUuid);
    }

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();
    // Job modal
    const openJobModal = () => jobModalRef.current.handleShow();
    const closeJobModal = () => jobModalRef.current.handleClose();
    // Application modal
    const openApplicationModal = () => applicationModalRef.current.handleShow();
    const closeApplicationModal = () => applicationModalRef.current.handleClose();

    function handleRedirectToButtonOne() {
        window.location.href = modalInformation.button1Redirect && modalInformation.button1Redirect
    }

    function handleRedirectToButtonTwo() {
        if (modalInformation.button2Redirect === 'reload') {
            window.location.reload();
        }
        else if (modalInformation.button2Redirect === 'new-job') {
            openJobModal();
            closeModal();
        }
        else {
            window.location.href = modalInformation.button2Redirect && modalInformation.button2Redirect
        }
    }

    return (
        <Fragment>

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                redirectButton1={modalInformation.button1Redirect && handleRedirectToButtonOne}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            {
                jobInformation && jobInformation.uuid
                &&
                <JobApplication
                    ref={applicationModalRef}
                    handleClose={closeApplicationModal}
                    title={jobsList.find(job => job.uuid === selectedJob)?.title}
                    jobUuid={selectedJob}
                    applicantName={`${talentToContact.first_name} ${talentToContact.middle_name && talentToContact.middle_name}`}
                    applicantUuid={talentToContact.uuid}
                />
            }

            <JobModal
                ref={jobModalRef}
                handleClose={closeJobModal}
            />

            <section id="blog-grid-area" className="py40">
                <div className="container">
                    <div className="row">
                        {/* Job filters */}
                        <div className="job-filters col-lg-4 col-md-12 col-sm-12 col-12">
                            <TalentAvailabilityFilters
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                                setCityValue={setCityValue}
                                category={categoryUuid}
                            />
                        </div>

                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="row">

                                {/* Talent Availability header */}
                                <div className="row talent-availability-header">
                                    <TalentAvailabilityHeader
                                        jobList={jobsList}
                                        handleJobClick={handleJobClick}
                                        selectedJob={selectedJob}
                                        openJobModal={openJobModal}
                                        handleCreateJob={handleCreateJob}
                                    />
                                </div>

                                {/* Talent list */}
                                {
                                    talentList && searchedTalents.map(talent => {
                                        return (
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6" key={talent.uuid}>
                                                <TalentAvailabilityCart
                                                    talentUuid={talent.uuid}
                                                    image={talent.profile_image_url}
                                                    name={`${talent.first_name} ${talent.middle_name ? talent.middle_name : ""} ${talent.last_name}`}
                                                    description={talent.description}
                                                    location={`${talent.city.name}, ${talent.city.state.name}, ${talent.city.state.country.name}`}
                                                    handleRequestContact={handleRequestContact}
                                                    handleVisitProfile={handleVisitProfile}
                                                />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt4">
                            <PaginationComponent
                                totalPages={Math.ceil(totalTalents / TALENTS_PER_PAGE)}
                                currentPage={page}
                                handlePageChange={handlePageChange}
                            />
                        </div>

                        {
                            toggleLateralFilters ?
                                <LateralFilters
                                    type={'talent-availability'}
                                    searchValue={searchValue}
                                    setSearchValue={setSearchValue}
                                    setCityValue={setCityValue}
                                    setToggleLateralFilters={setToggleLateralFilters}
                                    category={categoryUuid}
                                />
                                :
                                ''
                        }

                        <FilterButtonComponent
                            setToggleLateralFilters={setToggleLateralFilters}
                        />

                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default TalentAvailability