import api from '../../libs/API'
import useAuthentication from './useAuth';


const useCompanyRequests = () => {
    const headers = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
    };

    const { refreshToken } = useAuthentication();

    // Get company profile information
    const getCompanyProfileRequest = async (uuid, alreadyCalled) => {
        try {
            // Get token
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const companyProfile = await api.get(`/companies/${uuid}`, { headers });
            return companyProfile.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    return await refreshToken()
                        .then(() => getCompanyProfileRequest(uuid, true))
                        .catch(() => {
                            throw new Error("Por favor recarga la página");
                        });
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    // Update company
    const updateCompanyProfile = async (data, alreadyCalled) => {
        try {
            // Get token
            const token = localStorage.getItem('accessToken');
            headers.Authorization = `Bearer ${token}`;

            const payload = JSON.parse(atob(token.split('.')[1]));
            const uuid = payload.sub;

            const profileUpdated = await api.patch(`/companies/${uuid}`, data, { headers });
            localStorage.setItem('userInformation', JSON.stringify({
                role: payload.role,
                user: profileUpdated.data
            }));


            return profileUpdated.data.data;
        } catch (error) {
            console.error(error.response.data.message);
            // The token has expired
            if (error.response.data.message === "Expired") {
                if (alreadyCalled) {
                    throw new Error("Por favor recarga la página");
                } else {
                    try {
                        // Refresh token
                        await refreshToken();
                        await updateCompanyProfile(data, true)
                    } catch (error) {
                        throw new Error("Por favor recarga la página");
                    }
                }
            } else {
                throw new Error(error.response.data.message);
            }
        };
    };

    return { getCompanyProfileRequest, updateCompanyProfile };
};

export default useCompanyRequests;