import React, { Fragment, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// ----- Custom ----- //
import useJobsRequests from '../../hooks/backend/useJobs';
import useCompany from '../../hooks/backend/useCompany';

// ----- Components ----- //
import ProfileHeader from '../../components/profile/ProfileHeaderComponent';
import CompanyJobsProfile from '../../components/profile/CompanyJobsProfile';

import '../../styles/pages/my-profile.scss';


function SpecificCompanyProfile() {

    // ========== States ============ //
    const [profileInformation, setProfileInformation] = useState({});
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [location, setLocation] = useState('');
    const [jobsList, setJobsList] = useState([]);

    // ========== Custom ============
    const { getCompanyProfileRequest } = useCompany();
    const { getJobs } = useJobsRequests();

    // ========== Routing and params ============
    const { companyUuid } = useParams();

    // ========== Backend request ========== //
    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        // Company profile
        async function getCompanyInformation() {
            try {
                const companyResponse = await getCompanyProfileRequest(companyUuid);
                setLocation(`${companyResponse.city.name}, ${companyResponse.city.state.name}, ${companyResponse.city.state.country.name}`);
                setProfileInformation(companyResponse);
            } catch (error) {
                console.error(error.message);
            }
        }

        async function jobListFunction() {

            // Build filters object
            let filters = {
                limit: 4,
                oldest: 1,
                company: companyUuid
            }

            await getJobs(filters)
                .then(response => {
                    setJobsList(response.jobs);
                })
                .catch(console.error)
        };

        async function fetchData() {
            try {
                await Promise.all([
                    !profileInformation.uuid ? getCompanyInformation() : Promise.resolve([]),
                    jobListFunction()
                ]);
            } catch (error) {
                console.error(error.message);
            }
        }
        fetchData();


    }, [isInitialMount]);


    return (
        <Fragment>

            <section id="profile-area" className="py40">
                <div className="container">
                    {/* <!-- Profile header --> */}
                    <ProfileHeader
                        type={'visitor'}
                        profileImageUrl={profileInformation.profileImageUrl}
                        name={profileInformation.name}
                        location={location}
                        description={profileInformation.description}
                        footer={{ website: profileInformation.website }}
                        verified={profileInformation.verifiedCompany}
                    />

                    {/* <!-- Jobs --> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 company-jobs">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-12 justify-center">
                                <div className="profile-cart box-shadow">
                                    <div className="container">
                                        <div className="row company-profile-jobs-cart-inside">
                                            <div className="row company-profile-jobs-header">
                                                <h4 className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                    Empleos de {profileInformation.name}
                                                </h4>
                                            </div>

                                            <>
                                                {
                                                    jobsList &&
                                                    jobsList.map(job => {
                                                        return (
                                                            <CompanyJobsProfile
                                                                type={'visitor'}
                                                                job={job}
                                                                key={job.uuid}
                                                            />
                                                        )
                                                    })
                                                }
                                            </>

                                            <div className="row py10 profile-footer justify-content-end">
                                                <div className="col-12 align-center justify-content-end">
                                                    <a href="/jobs">
                                                        <p> <strong> Ver más...</strong></p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment >
    )
}

export default SpecificCompanyProfile