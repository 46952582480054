// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCO9RN6w34Gk-5q-6mQHrY9N9AQelNEetA",
  authDomain: "staffing-dev-6ca7a.firebaseapp.com",
  projectId: "staffing-dev-6ca7a",
  storageBucket: "staffing-dev-6ca7a.appspot.com",
  messagingSenderId: "584691380850",
  appId: "1:584691380850:web:930af60ed97e244bf5c8eb"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;