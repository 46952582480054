import React from 'react'

import '../styles/components/footer.scss';

function Footer() {
    return (
        <div className="footer-container">
            {/* <!--Footer Start --> */}
            <footer id="footer">
                <div className="container justify-center">
                    <div className="row footer-content">
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="footer-list-wedget pl20 single-item-mt-3 responsive-mt-32">
                                <div className="footer-heading">
                                    <h5>Links</h5>
                                </div>
                                <div className="footer-list pt20">
                                    <ul>
                                        <li><a href="index.html">Home </a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-6 col-6">
                            <div className="footer-list-wedget pl20 single-item-mt-3 responsive-mt-32">
                                <div className="footer-heading">
                                    <h5>Nosotros</h5>
                                </div>
                                <div className="footer-list pt20">
                                    <ul>
                                        <li><a href="about.html">Nosotros</a></li>
                                        <li><a href="service.html">Servicios</a></li>
                                        <li><a href="about.html"> Contáctanos</a></li>
                                        <li><a href="privacy-policy.html"> Política de privacidad</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 justify-center">
                            <div className="footer-list-wedget pl20 responsive-mt-32">
                                <div className="footer-heading">
                                    <h5>Información de Contacto</h5>
                                </div>
                                <div className="footer-contact-area footer-list pt20">
                                    <ul>
                                        <li>
                                            <i className="fa-solid fa-location-dot" /> Manizales, Caldas, Colombia
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-phone" /><a href="tel:573052343193">(+57) 3052343193</a>
                                        </li>
                                        <li>
                                            <i className="fa-solid fa-envelope" /><a href="mailto:soporte@datastorecolombia.co">soporte@datastorecolombia.co</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom-img">
                    <img src="https://staffing-app.s3.amazonaws.com/landing/footer.svg" alt="footer" />
                </div>
            </footer >
            {/* <!--Footer End-- > */}

            {/* < !--Copyright Start-- > */}
            <div className="copy-right">
                <div className="container">
                    <div className="row align-items-center" style={{ width: '100%' }}>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="copy-text">
                                <p>Fintech Software Solutions © 2023. All Rights Reserved</p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="copy-icon text-lg-right">
                                <ul>
                                    <li><a href="#!"><i className="fab fa-facebook-f icon"></i></a></li>
                                    <li><a href="#!"><i className="fab fa-twitter icon"></i></a></li>
                                    <li><a href="#!"><i className="fab fa-linkedin-in icon"></i></a></li>
                                    <li><a href="#!"><i className="fab fa-pinterest-p icon"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--Copyright End --> */}
        </div >
    )
}

export default Footer