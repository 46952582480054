import React, { Fragment, useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import useApplicantsRequests from '../../hooks/backend/useApplicants';
import useEmailsRequests from '../../hooks/backend/useEmails';

const JobApplication = forwardRef((props, ref) => {

    const {
        handleClose,
        title,
        jobUuid,
        applicantName,
        applicantUuid
    } = props;

    // ========== Custom hooks ============
    const { createApplicantRequest } = useApplicantsRequests();
    const { sendNewApplicationEmailRequest } = useEmailsRequests();

    // ========== States ============
    const [show, setShow] = useState(false);
    const [description, setDescription] = useState('');
    const [request, setRequest] = useState({});

    // ========== References ============
    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));
    const form = useRef(null);

    // ========== Backend request ============
    useEffect(() => {

        if (request.jobUuid) {
            async function jobApplicationFunction() {
                // Make request to backend
                await createApplicantRequest(request)
                    .then(async (response) => {
                        await sendNewApplicationEmailRequest({ id: response.id })
                            .then(window.location.reload())
                            .catch(console.error)
                    })
                    .catch(console.error())
            }
            jobApplicationFunction()
        } else {
            return;
        }
    }, [request]);


    // ========== Functions ============
    const handleSubmitApplication = (event) => {
        event.preventDefault();
        // Get user uuid
        const userInformation = JSON.parse(localStorage.getItem('userInformation'));
        const userUuid = userInformation.user.uuid;

        // Build request object
        // User apply for a job job
        let request = { jobUuid, userUuid }

        if (description) request.description = description;

        // Company ask to a user
        if (applicantUuid) {
            request.userUuid = applicantUuid;
            request.status = 'requested_by_company';
        };

        // Send request
        setRequest(request);
    };

    const handleChange = (event) => {
        const inputValue = event.target.value;
        if ((!/[\])}>[<{(]/.test(inputValue))) {
            setDescription(inputValue);
        }
    };

    return (
        <Fragment>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {
                        applicantName
                            ?
                            <Modal.Title>Solicitud de contacto</Modal.Title>
                            :
                            <Modal.Title>Aplicar a {title}</Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    <Form.Group
                        className="mb-3"
                        controlId="aboutYourself"
                        ref={form}
                    >
                        {
                            applicantName
                                ?
                                <Form.Label>
                                    <p>
                                        Al hacer clic en el botón 'contactar', le estarías manifestando tu interés a <strong>{applicantName}</strong> en trabajar contigo en el puesto de <strong>{title}</strong>. Si <strong>{applicantName}</strong> acepta, te proporcionaremos sus datos de contacto para que puedan finalizar los detalles de la entrevista.
                                    </p>
                                </Form.Label>
                                :
                                <Form.Label>Cuéntale a la empresa por qué estás interesado en este puesto</Form.Label>
                        }
                        <Form.Control
                            as="textarea"
                            name="description"
                            placeholder={applicantName && '¡Déjale saber por qué lo quieres contratar acá!'}
                            rows={3}
                            onChange={handleChange}
                            value={description}
                            autoFocus />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>
                        Cancelar
                    </Button>
                    <Button
                        className='btn-theme'
                        variant="primary"
                        onClick={(event) => handleSubmitApplication(event)}
                    >
                        {
                            applicantName
                                ?
                                'Contactar'
                                :
                                'Aplicar'
                        }
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment >
    )
})

export default JobApplication