import React, { Fragment } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import useDate from '../../hooks/useDates';

function MyJobsCart(props) {

    // ========== Custom ==========  //
    const { convertIsoToNormalFormat } = useDate();

    const {
        // uuid,
        jobUuid,
        image,
        status,
        applicantsNumber,
        date,
        title,
        description,
        updateJobHandler,
        disableJobHandler,
        seeApplicantHandler
    } = props;

    return (
        <Fragment>
            <div className="applicant-items box-shadow">
                <div className="applicant-imgs">
                    <a href="#">
                        {
                            image
                                ?
                                <img src={image} alt="cover" />
                                :
                                <img
                                    src="https://staffing-app.s3.amazonaws.com/profile-images/default-profile-picture.png"
                                    alt="cover default"
                                    style={{ objectFit: 'contain' }}
                                />
                        }
                        <div className="applicant-number">
                            {
                                status === 'disabled'
                                    ?
                                    <p className='text-danger'> <strong>Desahabilitado</strong></p>
                                    :
                                    <p> <strong>{applicantsNumber}</strong> aplicantes</p>
                            }
                        </div>
                    </a>
                </div>
                <div className="applicant-info">
                    <div className="meta-area">
                        <ul>
                            <li>
                                <i style={{ marginRight: '2px' }} className="fas fa-light fa-calendar" />
                            </li>
                            <li>
                                <p>
                                    {
                                        window.innerWidth > 500
                                            ?
                                            convertIsoToNormalFormat(date)
                                            :
                                            date.split('T')[0]
                                    }
                                </p>
                            </li>
                        </ul>
                    </div>
                    <h4>{title}</h4>
                    <p className='applicant-info-description'>
                        {
                            description ?
                                window.innerWidth > 1500 ?
                                    `${description.slice(0, 200)}...`
                                    :
                                    window.innerWidth < 900 ?
                                        `${description.slice(0, 100)}...`
                                        :
                                        description
                                :
                                ''
                        }
                    </p>
                    <div className='applicant-info-buttons'>
                        <Dropdown>
                            <Dropdown.Toggle id="dropdown-basic" className='btn-see-more mt4'>
                                Opciones
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item
                                    href="#"
                                    onClick={() => updateJobHandler(jobUuid)}
                                >
                                    <p>Actualizar</p>
                                </Dropdown.Item>
                                {
                                    status !== 'disabled'
                                    &&
                                    <Dropdown.Item
                                        href="#"
                                        onClick={() => disableJobHandler(jobUuid)}
                                    >
                                        <p>Deshabilitar</p>
                                    </Dropdown.Item>
                                }

                            </Dropdown.Menu>
                        </Dropdown>
                        <button onClick={() => seeApplicantHandler(jobUuid)} className="btn btn-theme btn-applicants mt4">
                            {
                                applicantsNumber === 0
                                    ?
                                    'Buscar aplicantes'
                                    :
                                    'Ver aplicantes'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default MyJobsCart