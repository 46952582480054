import React, { useState, forwardRef, useImperativeHandle } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ModalComponent = forwardRef((props, ref) => {
    const [show, setShow] = useState(false);
    const {
        handleClose,
        title,
        body,
        redirectButton1,
        redirectButton2,
        textButton1,
        textButton2,
    } = props;

    useImperativeHandle(ref, () => ({
        handleClose: () => setShow(false),
        handleShow: () => setShow(true),
    }));

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{body}</Modal.Body>
                <Modal.Footer>
                    {
                        textButton1 &&
                        <Button variant="secondary" onClick={() => {
                            handleClose();
                            redirectButton1 && redirectButton1()
                        }}>
                            {textButton1}
                        </Button>
                    }
                    <Button className='btn-theme' variant="primary" onClick={() => {
                        handleClose()
                        redirectButton2 && redirectButton2()
                    }}>
                        {textButton2}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
})

export default ModalComponent;