import React, { Fragment, useState, useEffect, useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

// ----- Custom ----- //
import usePreferences from '../../hooks/backend/usePreferences';
import useTalent from '../../hooks/backend/useTalent';
import useHistoryRequests from '../../hooks/backend/useHistory';
import useJobsRequests from '../../hooks/backend/useJobs';
import useDate from '../../hooks/useDates';

// ----- Components ----- //
import ProfileHeader from '../../components/profile/ProfileHeaderComponent';
import TalentExperienceProfile from '../../components/profile/TalentExperienceProfile';
import JobApplication from '../../components/jobs/JobApplication';

import '../../styles/pages/my-profile.scss';


function SpecificTalentProfile() {

    // ========== States ============ //
    const [profileInformation, setProfileInformation] = useState({});
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [location, setLocation] = useState('');
    const [days, setDays] = useState({
        Sunday: 'Domingo',
        Monday: 'Lunes',
        Tuesday: 'Martes',
        Wednesday: 'Miércoles',
        Thursday: 'Jueves',
        Friday: 'Viernes',
        Saturday: 'Sábado',
    });


    const [categoryPreferencesList, setCategoryPreferencesList] = useState([]);
    const [timePreferencesList, setTimePreferencesList] = useState([]);
    const [jobHistoryList, setJobHistoryList] = useState([]);
    const [jobInformation, setJobInformation] = useState(JSON.parse(sessionStorage.getItem('jobInformation')));

    // ========== Custom ============ //
    const { getTalentProfileRequest } = useTalent();
    const { getUserCategoryPreferencesRequest, getUserTimePreferencesRequest } = usePreferences();
    const { getUserJobsHistoryRequest } = useHistoryRequests();
    const { getJobType } = useJobsRequests();
    const { convertYMDToSimplified } = useDate();

    // ========== Routing and params ============
    const { talentUuid } = useParams();

    // ========== References ============ //
    const applicationModalRef = useRef();

    // ========== Backend request ========== //
    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        // Company profile
        async function getTalentInformation() {
            try {
                const talentResponse = await getTalentProfileRequest(talentUuid);
                setLocation(`${talentResponse.city.name}, ${talentResponse.city.state.name}, ${talentResponse.city.state.country.name}`);
                setProfileInformation(talentResponse);
            } catch (error) {
                console.error(error.message);
            }
        }

        // User category preferences 
        async function getUserCategoryPreferences() {
            try {
                const categoryPreferencesResponse = await getUserCategoryPreferencesRequest(talentUuid);
                setCategoryPreferencesList(categoryPreferencesResponse);
                return categoryPreferencesResponse;
            } catch (error) {
                console.error(error.message);
            }
        }

        // User time preferences
        async function getUserTimePreferences() {
            try {
                const timePreferencesResponse = await getUserTimePreferencesRequest(talentUuid);
                setTimePreferencesList(timePreferencesResponse);
                return timePreferencesResponse;
            } catch (error) {
                console.error(error.message);
            }
        }

        // User jobs history
        async function getUserJobsHistoryFunction() {
            try {
                const userJobsHistoryResponse = await getUserJobsHistoryRequest(talentUuid);
                setJobHistoryList(userJobsHistoryResponse);
                return userJobsHistoryResponse;
            } catch (error) {
                console.error(error.message);
            }
        }

        async function fetchData() {
            try {
                await Promise.all([
                    !profileInformation.uuid ? getTalentInformation() : Promise.resolve([]),
                    getUserCategoryPreferences(),
                    getUserTimePreferences(),
                    jobHistoryList.length === 0 && getUserJobsHistoryFunction(),

                ]);
            } catch (error) {
                console.error(error.message);
            }
        }
        fetchData();


    }, [isInitialMount]);

    // Modal functions
    const openApplicationModal = () => applicationModalRef.current.handleShow();
    const closeApplicationModal = () => applicationModalRef.current.handleClose();


    return (
        <Fragment>

            {
                jobInformation.uuid &&
                <JobApplication
                    ref={applicationModalRef}
                    handleClose={closeApplicationModal}
                    title={jobInformation.title}
                    jobUuid={jobInformation.uuid}
                    applicantName={`${profileInformation.firstName} ${profileInformation.middleName && profileInformation.middleName}`}
                    applicantUuid={profileInformation.uuid}
                />
            }

            <section id="profile-area" className="py40">
                <div className="container">
                    {/* <!-- Profile header --> */}
                    {
                        profileInformation &&
                        <ProfileHeader
                            type={'visitor'}
                            profileImageUrl={profileInformation.profileImageUrl}
                            name={`${profileInformation.firstName} ${profileInformation.middleName ? profileInformation.middleName : ""} ${profileInformation.lastName}`}
                            location={location}
                            description={profileInformation.description}
                            footer={{ lastCompany: 'Tesla', lastEducation: 'Universidad de Caldas' }}
                            jobInformationFlag={jobInformation.uuid ? true : false}
                            openApplicationModal={openApplicationModal}
                        />
                    }

                    {/* <!-- Profile information --> */}
                    <div className="row profile-additional-info">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 center-section">
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                    <div className="profile-cart box-shadow">
                                        <div className="container">
                                            <Tabs
                                                defaultActiveKey="biography"
                                                id="fill-tab-example"
                                                className="mb-3 mt-1"
                                                fill
                                            >
                                                <Tab eventKey="biography" title="Biografía">
                                                    <div className="container">
                                                        <div className="row">
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div className="profile-about-me">
                                                                    <h4>Sobre {`${profileInformation.firstName} ${profileInformation.middleName ? profileInformation.middleName : ""}`}</h4>
                                                                    <p>
                                                                        {profileInformation.description}
                                                                    </p>
                                                                </div>
                                                                <div className="talent-profile-my-preferences">
                                                                    <div className='row talent-profile-preferences-header'>
                                                                        <h4 className="col-lg-6 col-md-6 col-sm-6 col-6 align-center">
                                                                            Preferencias
                                                                        </h4>
                                                                    </div>
                                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                        <h5>Categorías</h5>
                                                                    </div>
                                                                    <div className="category-preferences">
                                                                        <ul>
                                                                            {
                                                                                categoryPreferencesList && categoryPreferencesList.map(category => {
                                                                                    if (category.category.uuid === 'e207272d-d465-4327-a80c-b76440cf7e03') return;
                                                                                    return (
                                                                                        <li
                                                                                            key={category.category.uuid}
                                                                                        >
                                                                                            <p
                                                                                                id={category.category.uuid}
                                                                                            >
                                                                                                {category.category.name}
                                                                                            </p>
                                                                                        </li>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>

                                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12 border-top'>
                                                                        <h5>Disponibilidad</h5>
                                                                    </div>
                                                                    <div className="category-preferences">
                                                                        <ul>
                                                                            {
                                                                                timePreferencesList && timePreferencesList.map(day => {
                                                                                    return (
                                                                                        <div className='row border-top' key={day.id}>
                                                                                            <h6 className='col-lg-3 col-md-3 col-sm-3 col-3'>
                                                                                                <strong> {days[day.day]} </strong>
                                                                                            </h6>
                                                                                            {
                                                                                                day.allDayFlag
                                                                                                    ?
                                                                                                    <p className='col-lg-6 col-md-6 col-sm-6 col-6'>Todo el día</p>
                                                                                                    :
                                                                                                    <p className='col-lg-9 col-md-9 col-sm-9 col-9'>
                                                                                                        {
                                                                                                            `${day.startHour.substr(0, day.startHour.length - 3)} - ${day.finishHour.substr(0, day.finishHour.length - 3)}`
                                                                                                        }
                                                                                                    </p>

                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="professional-experience" title="Experiencia" className='mt-1'>
                                                    <div className="container">
                                                        {/* <!-- Professional experience --> */}
                                                        {
                                                            jobHistoryList
                                                                ?
                                                                jobHistoryList.map(history => {
                                                                    const startDateConverted = convertYMDToSimplified(history.startDate);
                                                                    const finishDateConverted = history.actualJobFlag ? 'Presente' : convertYMDToSimplified(history.finishDate);
                                                                    const date = `${startDateConverted} - ${finishDateConverted}`;
                                                                    const location = history.remote ? 'Remote' : `${history.city.name}, ${history.city.state.name}, ${history.city.state.country.name}`
                                                                    return (
                                                                        < TalentExperienceProfile
                                                                            key={history.uuid}
                                                                            title={history.position}
                                                                            companyProfileImageUrl={history.company ? history.company.profile_image_url : ''}
                                                                            companyName={history.company ? history.company.name : history.companyName}
                                                                            location={location}
                                                                            description={history.description}
                                                                            footer={{ type: getJobType[history.type], date }}
                                                                        />
                                                                    )
                                                                })
                                                                :
                                                                <h4>{`${profileInformation.firstName} ${profileInformation.middleName ? profileInformation.middleName : ""}`} aún no tiene experiencia registrada en nuestra plataforma</h4>
                                                        }
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Fragment >
    )
}

export default SpecificTalentProfile;