import React, { Fragment, useState, useEffect } from 'react';

// Components
import BackToTopButton from '../components/BackToTopButton';
import ServiceCart from '../components/landing/ServiceCart';
import AboutUsTabs from '../components/landing/AboutUsTabs';
import Footer from '../components/Footer';

import '../styles/pages/landing.scss';

// Images
const homeImage = "https://staffing-app.s3.amazonaws.com/landing/home-image.jpg";
const aboutUsImage = "https://staffing-app.s3.amazonaws.com/landing/aboutUs.jpg";
const service1 = "https://staffing-app.s3.amazonaws.com/landing/service-icon1.png";
const service2 = "https://staffing-app.s3.amazonaws.com/landing/service-icon2.png";
const service3 = "https://staffing-app.s3.amazonaws.com/landing/service-icon3.png";
const service4 = "https://staffing-app.s3.amazonaws.com/landing/service-icon4.png";
const service5 = "https://staffing-app.s3.amazonaws.com/landing/service-icon5.png";
const service6 = "https://staffing-app.s3.amazonaws.com/landing/service-icon6.png";

function Landing() {

    const [showBackToTopButton, setShowBackToTopButton] = useState(false);

    useEffect(() => {
        document.addEventListener("scroll", () => {
            if (window.pageYOffset > 300) {
                setShowBackToTopButton(true);
            } else {
                setShowBackToTopButton(false);
            }
        });
    }, []);

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <Fragment>
            {/* Home start */}
            <section id="banner-one" className='py100'>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="home-one-banner-content">
                                <h1>Conectando oportunidades temporales</h1>
                                <h2>Soluciones innovadoras</h2>
                                <p>
                                    Ofrecemos una amplia variedad de trabajos temporales que se pueden adaptar a tus habilidades y disponibilidad, desde trabajos a tiempo parcial hasta proyectos puntuales.
                                </p>
                                <div className="banner-one-btn pt30">
                                    <a href="/register" className="btn btn-theme">Empezar ahora</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="banner-img">
                                <img src={homeImage} className="wow zoomIn" data-wow-duration="2.0s"
                                    data-wow-delay=".6s" alt="img" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Home end */}

            {/* Services start */}
            <section id="service" className="bg-color">
                <div className="container" style={{ display: "block" }}>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="section-title-center text-center pb30">
                                <h2 className="pb10">Nuestros servicios</h2>
                                <h3>
                                    Ofrecemos una diversidad de servicios tanto para empresas como para candidatos.
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ServiceCart
                            image={service1}
                            title={"Búsqueda de empleo"}
                            description={"Ofrecemos una amplia variedad de oportunidades de empleo ocasional, desde trabajos a tiempo parcial hasta proyectos puntuales. Busca trabajos que se adapten a tus habilidades y disponibilidad."}
                        />
                        <ServiceCart
                            image={service2}
                            title={"Matchmaking con empleadores"}
                            description={"Nuestro sistema avanzado de matchmaking te conecta con empleadores que buscan talentos como tú, asegurándonos de que encuentres el trabajo perfecto para ti."}
                        />
                        <ServiceCart
                            image={service3}
                            title={"Calificaciones y revisiones mutuas"}
                            description={"Ofrecemos un sistema de calificaciones y revisiones para que los empleadores y aplicantes puedan evaluar el rendimiento y la experiencia de trabajo."}
                        />
                        <ServiceCart
                            image={service4}
                            title={"Solicitud de empleo simplificada"}
                            description={"Nuestra plataforma te permite solicitar empleados de manera rápida y eficiente, con opciones de filtrado para encontrar al candidato perfecto."}
                        />
                        <ServiceCart
                            image={service5}
                            title={"Acceso a un pool de talentos"}
                            description={"Tenemos una amplia base de datos de candidatos capacitados y disponibles, lo que significa que encontrarás el talento adecuado para tus necesidades de manera rápida y eficiente."}
                        />
                        <ServiceCart
                            image={service6}
                            title={"Pagos automatizados"}
                            subtitle={"¡Próximamente!"}
                            description={"Sistema de pagos automatizados te permite hacer pagos a los empleados ocasionales de manera rápida y sencilla, sin tener que preocuparte por los detalles administrativos."}
                        />
                    </div>
                </div>
            </section>

            {/* Services end */}

            {/* How it works start */}
            <section id="how-it-work-home4" className="py100">
                <div className="row mb-4">
                    <h2 className='center-section'>¿Cómo funciona?</h2>
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="how-it-works-wrappers how-it-works-company">
                                <div className="how-it-works-title">
                                    <h2>Funcionamiento para empresas</h2>

                                </div>
                                <div className="how-it-inner-boxed">
                                    <div className="how-it-single-item">
                                        <div className="how-it-count-box">
                                            1
                                        </div>
                                        <div className="how-items-texted">
                                            <h3>
                                                <a href="#!">Regístrate y completa tu perfil</a>
                                            </h3>
                                            <p>Regístrate, completa tu perfil con la información requerida y así tendrás más oportunidades de buscar nuevos colaboradores.</p>
                                        </div>
                                    </div>
                                    <div className="how-it-single-item">
                                        <div className="how-it-count-box">
                                            2
                                        </div>
                                        <div className="how-items-texted">
                                            <h3>
                                                <a href="#!">Publica una nueva oferta</a>
                                            </h3>
                                            <p>Publica la nueva oferta de empleo y decide la cantidad de tiempo que tu empresa necesitará a esa persona.</p>
                                        </div>
                                    </div>
                                    <div className="how-it-single-item">
                                        <div className="how-it-count-box">
                                            3
                                        </div>
                                        <div className="how-items-texted">
                                            <h3>
                                                <a href="#!">¡Nuevos aplicantes!</a>
                                            </h3>
                                            <p>Espera que hayan aplicantes o busca algunos que se adapten a los requisitos del empleo.</p>
                                        </div>
                                    </div>
                                    <div className="how-it-single-item">
                                        <div className="how-it-count-box">
                                            4
                                        </div>
                                        <div className="how-items-texted">
                                            <h3>
                                                <a href="#!">Contrata y recibe analítica</a>
                                            </h3>
                                            <p>Podrás ver el perfil de cada aplicante y encontrar el candidato perfecto. Después de finalizar el proceso recibirás analítica.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* How it works applicants */}
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12 responsive-mt-32">
                            <div className="how-it-works-wrappers">
                                <div className="how-it-works-title">
                                    <h2>Funcionamiento para aplicantes</h2>
                                </div>
                                <div className="how-it-inner-boxed">
                                    <div className="how-it-single-item">
                                        <div className="how-it-count-box">
                                            1
                                        </div>
                                        <div className="how-items-texted">
                                            <h3>
                                                <a href="#!">Regístrate y completa tu perfil</a>
                                            </h3>
                                            <p>Regístrate, completa tu perfil con la información requerida y así tendrás más oportunidades de ser contratado.</p>
                                        </div>
                                    </div>
                                    <div className="how-it-single-item">
                                        <div className="how-it-count-box">
                                            2
                                        </div>
                                        <div className="how-items-texted">
                                            <h3>
                                                <a href="#!">Nuevas oportunidades</a>
                                            </h3>
                                            <p>Busca o espera una nueva oportunindad que se adapte a tus horarios y expectativas.</p>
                                        </div>
                                    </div>
                                    <div className="how-it-single-item">
                                        <div className="how-it-count-box">
                                            3
                                        </div>
                                        <div className="how-items-texted">
                                            <h3>
                                                <a href="#!">Aplica</a>
                                            </h3>
                                            <p>Aplica de manera sencilla a esa nueva oportunidad que llamo tu atención.</p>
                                        </div>
                                    </div>
                                    <div className="how-it-single-item">
                                        <div className="how-it-count-box">
                                            4
                                        </div>
                                        <div className="how-items-texted">
                                            <h3>
                                                <a href="#!">Pendiente de todas las fases</a>
                                            </h3>
                                            <p>Podrás consultar siempre el estado de tu aplicación y saber si esta ya fue revisada por la empresa que publicó la oferta</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* How it works end */}

            {/* AboutUs start */}
            <section id="about" className="py100 bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about-tabs ">
                                <img src={aboutUsImage} alt="aboutUsImage" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <AboutUsTabs />
                        </div>
                    </div>
                </div>
            </section>
            {/* AboutUs end */}

            <Footer />

            {showBackToTopButton && (
                <BackToTopButton handleClick={handleClick} />
            )}

        </Fragment>
    )
}

export default Landing