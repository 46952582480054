import React, { useState, useRef } from 'react';

import {
  TextField,
  InputAdornment,
  IconButton,
  Checkbox,
  FormControlLabel,
  FormHelperText
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

import '../../styles/components/auth.scss';

const validateInput = (type, value) => {
  switch (type) {
    case 'text':
      return (!/[^A-Za-zÀ-ÿ\s]+/.test(value));
    case 'number':
      return (!/^[0-9]{6,12}$/.test(value));
    case 'email':
      return (!/[\])}>[<{(]/.test(value));
    default:
      break;
  }
};

function RegisterStep2User({ stepCompleted, signUp }) {

  // States
  const form = useRef(null);

  // Input content
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  // errors
  const [errorFirstName, setErrorFirstName] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [errorLastName, setErrorLastName] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [errorEmail, setErrorEmail] = useState({ status: false, content: 'Este campo no debe estar vacío' });
  const [errorPassword, setErrorPassword] = useState({ status: false, content: 'Las contraseñas no coinciden' });
  const [errorCheckbox, setErrorCheckbox] = useState({ status: false, content: 'Este campo debe estar seleccionado' });

  const [error, setError] = useState({ status: false, content: '' });

  // Functions

  // -------------- Validations ----------------
  const formValidation = (data, formData) => {

    let flag = true;

    // First name
    if (!data.firstName && data.firstName === '') {
      setErrorFirstName({ ...errorFirstName, status: true });
      flag = false;
    };

    // Last name
    if (!data.lastName && data.lastName === '') {
      setErrorLastName({ ...errorLastName, status: true });
      flag = false;
    };

    // Email
    if (!data.email && data.email === '') {
      setErrorEmail({ ...errorEmail, status: true });
      flag = false;
    };

    // Checkbox
    if (!data.privacyPolicy) {
      setErrorCheckbox({ ...errorCheckbox, status: true });
      flag = false;
    };

    // Password
    if (data.password === '' || formData.get('confirm-password') === '') {
      setErrorPassword({ content: 'Este campo no debe estar vacío', status: true });
      flag = false;
    } else if (data.password !== formData.get('confirm-password')) {
      setErrorPassword({ ...errorPassword, status: true });
      flag = false;
    } else if (data.password.length < 8) {
      setErrorPassword({ content: 'La contraseña debe tener mínimo 8 caracteres', status: true });
      flag = false;
    };

    return flag;
  };

  const signUpGoogle = async () => {
    await signUp('google', {}, 'talent');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(form.current);
    const data = {
      firstName: formData.get('firstName'),
      middleName: formData.get('middleName'),
      lastName: formData.get('lastName'),
      email: formData.get('email'),
      password: formData.get('password'),
      privacyPolicy: formData.get('privacy-policy') === "on" && true,
      permissionToBeContactedFlag: formData.get('contact-me') === "on" && true,
    };
    const validateForm = formValidation(data, formData);

    if (validateForm) {
      // Make the petition to the backend
      await signUp('email', {
        email: data.email,
        password: data.password,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName
      }, 'talent').catch(error => {
        setError({
          status: true,
          content: error
        })
      })
    }
  };

  const handleChange = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    switch (inputName) {
      case 'firstName':
        setErrorFirstName({ ...errorFirstName, status: false });
        validateInput('text', inputValue) && setFirstName(inputValue);
        break;
      case 'middleName':
        validateInput('text', inputValue) && setMiddleName(inputValue);
        break;
      case 'lastName':
        setErrorLastName({ ...errorLastName, status: false });
        validateInput('text', inputValue) && setLastName(inputValue);
        break;
      case 'email':
        setErrorEmail({ ...errorEmail, status: false });
        validateInput('email', inputValue) && setEmail(inputValue);
        break;
      case 'password':
        setErrorPassword({ ...errorPassword, status: false });
        validateInput('email', inputValue) && setPassword(inputValue);
        break;
      default:
        break;
    }
  };

  return (
    <section id="login-area" className="py100">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="login-area">
              <div className="login-form-area">
                {/* Header */}
                <div className="form-area-heading text-center">
                  <h3>Crea una nueva cuenta</h3>
                  {/* <p>Con tu red social favorita.</p> */}
                  <p>Regístrate con Google o sigue los siguientes pasos:</p>
                </div>
                <div className="social-login-area">
                  <button
                    type="submit"
                    onClick={signUpGoogle}
                  >
                    <i className="fa-brands fa-google" />
                    Google
                  </button>
                </div>

                {/* Form */}
                <form action="#" ref={form} className='needs-validation'>
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          id="firstName"
                          size="small"
                          name="firstName"
                          label="Primer nombre"
                          autoComplete='given-name'
                          variant="outlined"
                          onChange={handleChange}
                          value={firstName}
                          helperText={errorFirstName.status && errorFirstName.content}
                          error={errorFirstName.status}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <TextField
                          fullWidth
                          id="middleName"
                          size="small"
                          name="middleName"
                          label="Segundo nombre"
                          autoComplete='additional-name'
                          variant="outlined"
                          onChange={handleChange}
                          value={middleName}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          id="lastName"
                          size="small"
                          name="lastName"
                          label="Apellidos"
                          autoComplete='family-name'
                          variant="outlined"
                          onChange={handleChange}
                          value={lastName}
                          helperText={errorLastName.status && errorLastName.content}
                          error={errorLastName.status}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          id="email"
                          size="small"
                          name="email"
                          label="Correo electrónico"
                          autoComplete='email'
                          variant="outlined"
                          onChange={handleChange}
                          value={email}
                          helperText={errorEmail.status && errorEmail.content}
                          error={errorEmail.status}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">

                        <TextField
                          required
                          fullWidth
                          id="new-password"
                          size="small"
                          name="password"
                          label="Contraseña"
                          autoComplete="new-password"
                          onChange={handleChange}
                          value={password}
                          helperText={errorPassword.status && errorPassword.content}
                          error={errorPassword.status}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endadornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={(event) => event.preventDefault()}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="form-group">
                        <TextField
                          required
                          fullWidth
                          id="confirm-password"
                          size="small"
                          name="confirm-password"
                          label="Confirmar contraseña"
                          autoComplete="new-password"
                          onChange={() => setErrorPassword({ ...errorPassword, status: false })}
                          // value={password}
                          helperText={errorPassword.status && errorPassword.content}
                          error={errorPassword.status}
                          type={showPassword ? 'text' : 'password'}
                          InputProps={{
                            endadornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => setShowPassword(!showPassword)}
                                  onMouseDown={(event) => event.preventDefault()}
                                  edge="end"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12 col-12">
                      <div className="password-remember align-center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name='privacy-policy'
                              onChange={() => setErrorCheckbox({ ...errorCheckbox, status: false })}
                              required
                            />
                          }
                          label={
                            <p>
                              Estoy de acuerdo con la <a href="#!">política de privacidad</a>
                            </p>
                          }
                          error
                        />
                      </div>
                      {
                        errorCheckbox.status && <FormHelperText className='align-center' error>Este recuadro debe ser seleccionado</FormHelperText>
                      }

                    </div>
                    <div className="col-lg-12 col-md-6 col-sm-12 col-12">
                      <div className="password-remember align-center">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name='contact-me'
                              defaultChecked
                            />
                          }
                          label={
                            <p>
                              Empresas pueden contactarme cuando tengan una nueva oferta laboral
                            </p>
                          }
                          error
                        />
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="login-button">
                        <button
                          type="submit"
                          className="btn btn-theme"
                          onClick={handleSubmit}
                        >
                          Registrarme
                        </button>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="login-desc text-center">
                        <p>¿Ya tienes una cuenta?<a href="/login"> Ingresa</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default RegisterStep2User