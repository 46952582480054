import React, { useState } from 'react';

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import "../../styles/components/landing/aboutUsTabs.scss";

const icon1 = "https://staffing-app.s3.amazonaws.com/landing/home-icon1.png";
const icon2 = "https://staffing-app.s3.amazonaws.com/landing/home-icon2.png";
const icon3 = "https://staffing-app.s3.amazonaws.com/landing/home-icon3.png";

function AboutUsTabs() {

    const aboutUsText = {
        title: "Nosotros",
        description: "Somos un equipo dedicado y apasionado de profesionales con experiencia en el mercado laboral. Nos enorgullece ofrecer un servicio de clase mundial y ayudar a las personas a encontrar trabajo ocasional de calidad. Estamos comprometidos a brindar una experiencia excepcional a cada empleador y aplicante que se una a nuestra plataforma."
    };

    const missionText = {
        title: "Misión",
        description: "Nuestra misión es conectar a empleadores y aplicantes de manera eficiente y efectiva, brindando acceso a una amplia variedad de oportunidades de empleo ocasional. Nos enfocamos en ayudar a los aplicantes a encontrar trabajos que se adapten a sus habilidades y preferencias, y trabajamos con los empleadores para entender sus necesidades y encontrar aplicantes idóneos."
    };
    const visionText = {
        title: "Visión",
        description: "Nuestra visión es crear una comunidad en la que empleadores y aplicantes puedan trabajar juntos de manera eficiente y satisfactoria. Buscamos ofrecer herramientas y recursos para ayudar a los empleadores a evaluar y seleccionar a los aplicantes adecuados, y brindar apoyo a los aplicantes a lo largo de todo el proceso de aplicación y empleo."
    };

    const [infoToShow, setInfoToShow] = useState(aboutUsText);

    const onSelectTab = (tabSelected) => {
        switch (tabSelected) {
            case 'aboutUs':
                setInfoToShow(aboutUsText);
                break;
            case 'mission':
                setInfoToShow(missionText);
                break;
            case 'vision':
                setInfoToShow(visionText);
                break;
            default:
                setInfoToShow(aboutUsText);
                break;

        }
    }


    return (
        <div className="about-right-side-tabs">
            {/* <div className="tabs-heading">
                <h2>{infoToShow.title}</h2>
                <p className="py10">{infoToShow.description}</p>
            </div> */}
            <Tabs
                defaultActiveKey="aboutUs"
                className="mb-4 navs-title"
                onSelect={event => onSelectTab(event)}
            >
                <Tab className='nav-link' eventKey="aboutUs" title={aboutUsText.title}>
                    <div className="" id="about-1" role="tabpanel"
                        aria-labelledby="about-tab">
                        <h2>{infoToShow.title}</h2>
                        <p className="py10">{infoToShow.description}</p>
                        <ul>
                            <li className="py10 list-content">
                                <img src={icon1} alt="icon1" className="pr10 " />
                                <p>
                                    Nuestra plataforma está diseñada para conectar a los empleadores con aplicantes en busca de oportunidades de empleo ocasional.
                                </p>
                            </li>
                            <li className="py10 list-content">
                                <img src={icon2} alt="icon2" className="pr10 " />
                                <p>
                                    Nuestro equipo de servicio al cliente está disponible para responder cualquier pregunta y brindar un excelente servicio, para que la experiencia sea lo más positiva posible para todos.
                                </p>
                            </li>
                        </ul>
                    </div>
                </Tab>
                <Tab className='nav-link' eventKey="mission" title={missionText.title}>
                    <div className="" id="about-1" role="tabpanel"
                        aria-labelledby="about-tab">
                        <h2>{infoToShow.title}</h2>
                        <p className="py10">{infoToShow.description}</p>
                        <ul>
                            <li className="py10 list-content">
                                <img src={icon1} alt="icon1" className="pr10 " />
                                <p>
                                    Creemos en la responsabilidad social y trabajamos activamente para hacer una diferencia positiva en la comunidad y en la sociedad en general.
                                </p>
                            </li>
                            <li className="py10 list-content">
                                <img src={icon3} alt="icon3" className="pr10 " />
                                <p>
                                    Estamos constantemente buscando maneras de mejorar nuestra plataforma y ofrecer un servicio aún más eficiente y avanzado.
                                </p>
                            </li>
                        </ul>
                    </div>
                </Tab>
                <Tab className='nav-link' eventKey="vision" title={visionText.title}>
                    <div className="" id="about-1" role="tabpanel" aria-labelledby="about-tab">
                        <h2>{infoToShow.title}</h2>
                        <p className="py10">{infoToShow.description}</p>
                        <ul>
                            <li className="py10 list-content">
                                <img src={icon1} alt="icon1" className="pr10 " />
                                <p>
                                    Creemos en la importancia de crear una comunidad sólida de empleadores y aplicantes, donde todos puedan prosperar y encontrar oportunidades.
                                </p>
                            </li>
                            <li className="py10 list-content">
                                <img src={icon2} alt="icon2" className="pr10 " />
                                <p>
                                    Nos esforzamos por hacer que la búsqueda de empleo y la contratación sea lo más fácil y eficiente posible.
                                </p>
                            </li>
                        </ul>
                    </div>
                </Tab>
            </Tabs>
        </div>
    )
}

export default AboutUsTabs