import React, { Fragment, useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Custom
import useJobsRequests from '../../hooks/backend/useJobs';
import useApplicantsRequests from '../../hooks/backend/useApplicants';
import useDate from '../../hooks/useDates';
import useAnalytics from '../../hooks/useAnalytics';

// Components
import ModalComponent from '../../components/ModalComponent';
import JobApplication from '../../components/jobs/JobApplication';
import UpdateApplicationStatusModal from '../../components/applications/UpdateApplicationStatus';

import '../../styles/pages/job-details.scss';

// Constrains
const getStatus = {
    'requested_by_user': { className: '', status: 'Aplicado' },
    'requested_by_company': { className: 'text-info', status: 'Requerido por empresa' },
    'in_review': { className: 'text-warning', status: "En revisión" },
    'rejected_by_user': { className: 'text-danger', status: "Oferta cerrada" },
    'rejected_by_company': { className: 'text-danger', status: "Oferta cerrada" },
    'in_contact': { className: 'text-primary', status: "En contacto" },
    'hired': { className: 'text-success', status: "Contratado" },
};

function ApplicationDetails() {


    // ========== References ============
    const modalRef = useRef();
    const applicationModalRef = useRef();
    const updateStatusModalRef = useRef();

    // ========== Custom hooks ============
    const { getJobType, getJobs } = useJobsRequests();
    const { getApplicationRequest } = useApplicantsRequests();
    const { convertIsoToNormalFormat } = useDate();
    const applicationsAnalyticsTracker = useAnalytics('Applications');

    // ========== States ============
    const [isInitialMount, setIsInitialMount] = useState(true);
    const [applicationDetails, setApplicationDetails] = useState({});
    const [jobsSuggestions, setJobsSuggestions] = useState([]);
    const [editApplicationFlag, setEditApplicationFlag] = useState(false);


    // Modal
    const [modalInformation, setModalInformation] = useState({
        title: 'Error interno',
        content: 'Ha ocurrido un error inesperado por favor inténtalo nuevamente',
        button2Content: 'Volver',
        button2Redirect: '',
    });

    // ========== Routing and params ============
    const { applicationId } = useParams();
    const navigate = useNavigate();

    // ========== Backend request ==========  //
    // ----- Job List ----- //
    useEffect(() => {

        if (isInitialMount) {
            setIsInitialMount(false);
            return;
        }

        async function getApplicationFunction() {
            await getApplicationRequest(applicationId)
                .then(response => setApplicationDetails(response))
                .catch(error => {
                    console.error(error);
                    setModalInformation({ ...modalInformation, content: error.message, button2Redirect: 'reload' });
                    openModal();
                })
        };

        getApplicationFunction();
    }, [isInitialMount]);

    // Job suggestions
    useEffect(() => {
        if (applicationDetails.job && applicationDetails.job?.job_category) {
            async function getJobSuggestions() {
                await getJobs({ limit: 5, page: 1, category: applicationDetails.job?.job_category[0].category.uuid })
                    .then(response => {
                        const suggestions = response.jobs.filter(job => job.uuid !== applicationDetails.job?.uuid);
                        setJobsSuggestions(suggestions);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
            getJobSuggestions();
        } else {
            return;
        }

    }, [applicationDetails]);


    const footerToShow = () => {

        if (applicationDetails.status?.includes('reject')) {
            return (
                <p>Oferta cerrada</p>
            );
        }

        if (applicationDetails.status === 'requested_by_company') {
            return (
                <button
                    href=""
                    className="btn btn-theme btn-apply"
                    onClick={(event) => {
                        event.preventDefault();
                        openUpdateStatusModal();
                    }}
                >
                    Responder
                </button>
            );
        } else {
            return (
                <button
                    href=""
                    className="btn btn-theme btn-apply"
                    onClick={(event) => {
                        event.preventDefault();
                        setEditApplicationFlag(true);
                        openUpdateStatusModal();
                    }}>
                    Actualizar
                </button>
            );
        };
    }

    // Modal functions
    const openModal = () => modalRef.current.handleShow();
    const closeModal = () => modalRef.current.handleClose();

    // Create application modal functions
    const openApplicationModal = () => applicationModalRef.current.handleShow();
    const closeApplicationModal = () => applicationModalRef.current.handleClose();

    // Update application status modal functions
    const openUpdateStatusModal = () => updateStatusModalRef.current.handleShow();
    const closeUpdateStatusModal = () => updateStatusModalRef.current.handleClose();

    function handleRedirectToButtonTwo() {
        if (modalInformation.button2Redirect === 'reload') {
            window.location.reload();
        } else {
            window.location.href = modalInformation.button2Redirect ? modalInformation.button2Redirect : null;
        }
    };

    return (
        <Fragment>

            <ModalComponent
                ref={modalRef}
                title={modalInformation.title}
                body={modalInformation.content}
                handleClose={closeModal}
                textButton1={modalInformation.button1Content}
                textButton2={modalInformation.button2Content}
                redirectButton2={modalInformation.button2Redirect && handleRedirectToButtonTwo}
            />

            <UpdateApplicationStatusModal
                ref={updateStatusModalRef}
                handleClose={closeUpdateStatusModal}
                selectedApplication={
                    {
                        id: applicationDetails.id,
                        status: applicationDetails.status && getStatus[applicationDetails.status].status,
                        application: applicationDetails
                    }
                }
                editApplicationFlag={editApplicationFlag}
                description={applicationDetails.description}
            />

            <section id="job-details-main-area" className="py40">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-sm-12 col-12">
                            <div className="job-details-area">
                                <div className="job-main-details box-shadow ">
                                    <div className="job-details-company-image">
                                        {
                                            applicationDetails.job && applicationDetails.job.company?.profile_image_url
                                                ?
                                                <img src={applicationDetails.job?.company?.profile_image_url} alt="company" />
                                                :
                                                <img
                                                    src="https://staffing-app.s3.amazonaws.com/profile-images/default-profile-picture.png"
                                                    alt="Profile Picture Default"
                                                    style={{ objectFit: 'contain' }}
                                                />
                                        }
                                    </div>
                                    <div className="job-main-details-text">
                                        <div className="job-main-details-heading">
                                            <h2>{applicationDetails.job?.title}</h2>
                                        </div>
                                        <div className="job-main-details-author">
                                            <div className="job-author-view">
                                                <ul>
                                                    <li>
                                                        <a href="">
                                                            {
                                                                (applicationDetails.job?.job_category && applicationDetails.job?.job_category.length !== 0)
                                                                && applicationDetails.job?.job_category[0].category.name
                                                            }
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <i className="fa-solid fa-eye"></i>
                                                            {applicationDetails.status && getStatus[applicationDetails.status].status}
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="job-main-details-paragraph">
                                            <h3>Mensaje de {applicationDetails.status === 'requested_by_company' ? 'la empresa' : 'aplicación'}</h3>
                                            <p>
                                                {
                                                    applicationDetails.description
                                                        ?
                                                        applicationDetails.description
                                                        :
                                                        `No escribiste ningún mensaje adicional. Estos mensajes ayudan a la empresa a conocer mejor al candidato antes de la entrevista.
                                                    Si quieres escribir un mensaje adicional, puedes actualizar la aplicación dando click en el botón actualizar.`
                                                }
                                            </p>
                                            {
                                                applicationDetails.job?.description &&
                                                <div>
                                                    <h3>Descripción del empleo</h3>
                                                    <p>{applicationDetails.job?.description}</p>
                                                </div>
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                    <div className="apply-job-area">
                                        <ul>
                                            <li>{convertIsoToNormalFormat(applicationDetails.createdAt)}</li>
                                            <li>
                                                {footerToShow()}
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Company information */}
                                <div className="company-author box-shadow">
                                    <ul>
                                        <li className="media">
                                            <div className="company-img">
                                                {
                                                    applicationDetails.job && applicationDetails.job.company?.profile_image_url
                                                        ?
                                                        <img src={applicationDetails.job?.company?.profile_image_url} alt="company" />
                                                        :
                                                        <img
                                                            src="https://staffing-app.s3.amazonaws.com/profile-images/default-profile-picture.png"
                                                            alt="Profile Picture Default"
                                                            style={{ objectFit: 'contain' }}
                                                        />
                                                }
                                                <div>
                                                    <a
                                                        href={`/company/${applicationDetails.job?.company.uuid}`}
                                                        className="btn btn-theme btn-apply"
                                                        onClick={() => applicationsAnalyticsTracker({ action: 'talent_visit_company_profile', label: applicationDetails.job?.company.uuid })}
                                                    >
                                                        Ver perfil
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="company-body">
                                                <div className="company-header">
                                                    <div className="company-name">
                                                        <h4>{applicationDetails.job?.company.name}</h4>
                                                        <ul className="company-review">
                                                            <li> <i className="fa fa-star"></i>
                                                            </li>
                                                            <li> <i className="fa fa-star"></i>
                                                            </li>
                                                            <li> <i className="fa fa-star"></i>
                                                            </li>
                                                            <li> <i className="fa fa-star"></i>
                                                            </li>
                                                            <li> <i className="fa fa-star"></i>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="company-description">
                                                    {
                                                        applicationDetails.job?.company.description ?
                                                            applicationDetails.job?.company.description.length >= 200 ?
                                                                <p>
                                                                    {applicationDetails.job?.company.description.slice(0, 200)}
                                                                    <a
                                                                        className='text-primary'
                                                                        href={`/company/${applicationDetails.job?.company.uuid}`}
                                                                        onClick={() => applicationsAnalyticsTracker({ action: 'talent_visit_company_profile', label: applicationDetails.job?.company.uuid })}
                                                                    >
                                                                        ...more
                                                                    </a>
                                                                </p>
                                                                :
                                                                <p>
                                                                    {applicationDetails.job?.company.description}
                                                                </p>
                                                            :
                                                            ''
                                                    }
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <!-- Additional info --> */}
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12 additional-info">
                            <div className="left-head-job right-side">
                                <div className="left-job-page box-shadow">
                                    <div className="left-job">
                                        <h4>Información adicional</h4>
                                        <ul>
                                            {/* Wage */}
                                            {
                                                applicationDetails.job?.wageValue && applicationDetails.job?.wageValue !== 0
                                                    ?
                                                    <li>
                                                        <span>
                                                            <i className="fa fa-wallet"></i>
                                                        </span>
                                                        <p>
                                                            {
                                                                `${applicationDetails.job?.wageCurrency.symbol} ${Number(applicationDetails.job?.wageValue).toFixed(0).toLocaleString('es-CO')} ${applicationDetails.job?.wageCurrency.abbreviation}`
                                                            }
                                                        </p>
                                                    </li>
                                                    :
                                                    ''
                                            }
                                            <li>
                                                <span>
                                                    <i className="fa-solid fa-location-dot"></i>
                                                </span>
                                                <p>
                                                    {
                                                        applicationDetails.job?.remote
                                                            ?
                                                            'Remoto'
                                                            :
                                                            `${applicationDetails.job?.city?.name}, ${applicationDetails.job?.city?.state.country.name}`
                                                    }
                                                </p>
                                            </li>
                                            <li>
                                                <span>
                                                    <i className="fa-solid fa-briefcase"></i>
                                                </span>
                                                <p>{getJobType[applicationDetails.job?.type]}</p>
                                            </li>
                                            {
                                                applicationDetails.job?.startDate &&
                                                <li>
                                                    <span>
                                                        <i className="fa-solid fa-calendar"></i>
                                                    </span>
                                                    <p className="m0"><b>Fecha de inicio: </b> </p>
                                                    <p>
                                                        {applicationDetails.job?.startDate}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                applicationDetails.job?.finishDate &&
                                                <li>
                                                    <span>
                                                        <i className="fa-solid fa-calendar"></i>
                                                    </span>
                                                    <p className="m0"><b>Termina: </b></p>
                                                    <p>
                                                        {applicationDetails.job?.finishDate}
                                                    </p>
                                                </li>
                                            }
                                            {
                                                applicationDetails.job?.startHour &&
                                                <li>
                                                    <span>
                                                        <i className="fa-solid fa-clock"></i>
                                                    </span>
                                                    <p className="m0"><b>Hora inicial: </b></p>
                                                    <p>
                                                        {applicationDetails.job?.startHour}
                                                    </p>
                                                </li>

                                            }
                                            {
                                                applicationDetails.job?.finisHour &&
                                                <li>
                                                    <span>
                                                        <i className="fa-solid fa-clock"></i>
                                                    </span>
                                                    <p className="m0"><b>Hora final: </b></p>
                                                    <p>
                                                        {applicationDetails.job?.finishHour}
                                                    </p>
                                                </li>

                                            }
                                        </ul>
                                    </div>
                                </div>
                                {/* <!-- Suggestions --> */}
                                {
                                    jobsSuggestions.length !== 0 &&
                                    <div className="left-job-page box-shadow">
                                        <div className="left-job">
                                            <h4>Otros empleos</h4>
                                            <div onClick={(event) => navigate(`/jobs/${event.target.id}`)}>
                                                {
                                                    jobsSuggestions.map(job => {
                                                        return (
                                                            <div
                                                                className="other-job"
                                                                key={job.uuid}
                                                                id={job.uuid}
                                                            >
                                                                <a
                                                                    href=""
                                                                    className='align-center'
                                                                    id={job.uuid}
                                                                >
                                                                    <div className="job-img">
                                                                        {
                                                                            job['company.profile_image_url']
                                                                                ?
                                                                                <img src={job['company.profile_image_url']} alt="company" id={job.uuid} />
                                                                                :
                                                                                <img
                                                                                    src="https://staffing-app.s3.amazonaws.com/profile-images/default-profile-picture.png"
                                                                                    alt="Profile Picture Default"
                                                                                    style={{ objectFit: 'contain' }}
                                                                                />
                                                                        }
                                                                    </div>
                                                                    <div className="job-details-content" id={job.uuid}>
                                                                        <p id={job.uuid}>
                                                                            <b id={job.uuid}> {job.title} </b>
                                                                        </p>
                                                                        <p id={job.uuid}> {getJobType[job.type]} </p>
                                                                    </div>
                                                                </a>
                                                                <div className='other-job-arrow'>
                                                                    <i className="fa-solid fa-chevron-right"></i>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    )
}

export default ApplicationDetails