import React from 'react'

import "../../styles/components/landing/serviceCart.scss";

function ServiceCart({ image, title, description, subtitle }) {
    return (
        <div className="col-lg-4 col-md-6 col-sm-12 col-12" style={{ display: "flex" }}>
            <div className="service-box box-shadow border-radius wow fadeInUp" data-wow-duration="2.0s"
                data-wow-delay=".1s">
                <div className="service-box-inner text-center">
                    <img src={image} alt="img" />

                    <h3><a href="#!">{title}</a></h3>
                    {subtitle && <h6>{subtitle}</h6>}
                    <p> {description} </p>
                    <a href="#!">¡Empieza ahora!</a>
                </div>
            </div>
        </div>
    )
}

export default ServiceCart;