import React, { Fragment } from 'react';

function CompanyJobsProfile(props) {

    const {
        type,
        job,
        handleUpdateJob
    } = props;

    const jobLocation = job.remote === 1 ? 'Remoto' : `${job['city.name']}, ${job['city.state.name']}`;

    return (
        <Fragment>
            <div className="row job-info" key={job.uuid}>
                <div
                    className="col-lg-3 col-md-3 col-sm-3 col-3 profile-image center-section pb24">
                    {
                        job['company.profile_image_url']
                            ?
                            <img
                                src={job['company.profile_image_url']}
                                alt="company"
                            />
                            :
                            <img
                                src="https://staffing-app.s3.amazonaws.com/profile-images/default-profile-picture.png"
                                alt="Profile Picture Default"
                                style={{ objectFit: 'contain' }}
                            />
                    }
                </div>
                <div className="col-lg-9 col-md-9 col-sm-9 col-9 pb12">
                    <div className="row profile-header-info">
                        <div className="col-lg-8 col-md-8 col-sm-9 col-8 align-center">
                            <div className="job-name">
                                <h4>{job.title}</h4>
                                <div className="job-location">
                                    <i className="fa-solid fa-location-dot"></i>
                                    <h6>{jobLocation}</h6>
                                </div>
                            </div>
                        </div>
                        {
                            type === 'visitor'
                                ?
                                <div className="col-lg-4 col-md-4 col-sm-3 col-4 align-center edit-job">
                                    <div onClick={() => window.location.href = `/jobs/${job.uuid}`}>
                                        <button className="btn btn-theme">
                                            Ver empleo
                                        </button>
                                        <div>
                                            <a href="">
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="col-lg-4 col-md-4 col-sm-3 col-4 align-center edit-job">
                                    <div onClick={event => handleUpdateJob(event, job)}>
                                        <button type="submit" className="btn btn-theme">
                                            Ver empleo
                                        </button>
                                        <div>
                                            <a href="">
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                        }
                    </div>
                    {
                        job.description.length >= 200 ?
                            <p className="job-description">
                                {job.description.slice(0, 200)}
                                <a className='text-primary' href={`/jobs/${job.uuid}`}> ...more</a>
                            </p>
                            :
                            <p className="job-description">
                                {job.description}
                            </p>
                    }
                    <p className='job-description-2'>
                        {job.description.slice(0, 100)}
                        <a className='text-primary' href={`/jobs/${job.uuid}`}> ...more</a>
                    </p>
                </div>
                <div className="row py10 job-footer">
                    <div className="col-6 center-section">
                        <i className="fa-solid fa-users"></i>
                        <p><strong>{job.applicantsNumber}</strong> aplicaciones</p>
                    </div>
                    <div className="col-6 center-section">
                        <i className="fa-solid fa-gauge"></i>
                        <p>Activo</p>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default CompanyJobsProfile