import { useState } from 'react';
import { v4 as uuid } from 'uuid';
import AWS from 'aws-sdk';
// require('dotenv').config();

// Set up AWS credentials
AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

// Create S3 instance
const s3 = new AWS.S3();

const useS3Request = () => {

    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);

    // Function to upload files to S3
    const uploadFile = (file, folderName) => {

        const fileName = `${uuid()}-${file.name}`;
        const filePath = `${folderName}/${fileName}`;

        // Set up upload parameters
        const params = {
            Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
            Key: filePath,
            Body: file,
        };

        // Allow to upload big files
        const options = {
            partSize: 10 * 1024 * 1024, // 10 MB
            queueSize: 1,
        };

        // Upload file to S3
        return new Promise((resolve, reject) => {
            s3.upload(params, options)
                .on('httpUploadProgress', (event) => {
                    const progress = Math.round((event.loaded / event.total) * 100);
                    setProgress(progress);
                })
                .send((err, data) => {
                    if (err) {
                        console.error(err);
                        setError(err.message);
                        reject(err);
                    } else {
                        setUrl(data.Location);
                        resolve(data.Location);
                    }
                });
        });
    };

    return {
        progress,
        uploadFile
    }
};

export default useS3Request;
